.AboutUsPage {
    display: flex;
    position: relative;
    width: 100%;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.AboutUsPage .divImageBack {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    object-fit: cover;
}

.AboutUsPage .divImageBack .backimgonediv {
    display: flex;
    position: relative;
    width: 100%;
    /* height: 100%; */
    object-fit: contain;
}

.AboutUsPage .divImageBack .backimgonediv .backimgone {
    width: 100%;
    /* height: 100%; */
    object-fit: contain;
}

.AboutUsPage .contentText {
    display: flex;
    position: relative;
    width: 100%;
    /* background-color: blue; */
    flex-direction: column;
    align-items: center;
    justify-content: start;
}

.AboutUsPage .contentText .textCN {
    font-size: .22rem;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
    line-height: .42rem;
    letter-spacing: .02rem;
    -webkit-background-clip: text;
    text-align: left;
}

.AboutUsPage .contentText .textEN {
    font-size: .22rem;
    font-family: Inter-Italic, Inter;
    font-weight: normal;
    color: #535353;
    line-height: .46rem;
    -webkit-background-clip: text;
    text-align: left;
}

.AboutUsPage .contentText .textSemibold {
    font-weight: 600;
}

.AboutUsPage .contentText .textENSemibold {
    font-family: Inter-BoldItalic, Inter;
}

.AboutUsPage .contentText .text1 {
    width: 7.24rem;
    margin-top: 3.9rem;
    margin-left: 5.6rem;
}

.AboutUsPage .contentText .text2 {
    width: 7.24rem;
    margin-top: .10rem;
    margin-left: 5.6rem;
}

.AboutUsPage .contentText .text3 {
    width: 7.23rem;
    margin-top: 1.4rem;
    margin-left: -6.5rem;
}

.AboutUsPage .contentText .text4 {
    width: 5.64rem;
    margin-top: 7.2rem;
    margin-left: -4.2rem;
    transform: scale(1);
    line-height: .35rem;

}

.AboutUsPage .contentText .text5 {
    width: 5.64rem;
    margin-top: .14rem;
    margin-left: -4.2rem;
    transform: scale(1);
    font-size: .19rem;
    line-height: .31rem;
}

.AboutUsPage .contentText .text6 {
    width: 5.64rem;
    margin-top: 1.9rem;
    margin-left: 4.2rem;
    transform: scale(1);
    line-height: .35rem;
}

.AboutUsPage .contentText .text7 {
    width: 5.64rem;
    margin-top: .14rem;
    margin-left: 4.2rem;
    transform: scale(1);
    font-size: .19rem;
    line-height: .31rem;
}

.AboutUsPage .contentText .text8 {
    width: 5.64rem;
    margin-top: 1.8rem;
    margin-left: -4.2rem;
    transform: scale(1);
    line-height: .35rem;
}

.AboutUsPage .contentText .text81 {
    width: 5.64rem;
    margin-top: .14rem;
    margin-left: -4.2rem;
    transform: scale(1);
    font-size: .19rem;
    line-height: .31rem;
}

.AboutUsPage .contentText .textCN2 {
    font-size: .26rem;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: .5rem;
    letter-spacing: .02rem;
    -webkit-background-clip: text;
    text-align: left;
    transform: scale(.9);
}

.AboutUsPage .contentText .textEN2 {
    font-size: .24rem;
    font-family: Inter-Italic, Inter;
    font-weight: normal;
    color: #FFFFFF;
    line-height: .46rem;
    -webkit-background-clip: text;
    text-align: left;
    transform: scale(.9);
}

.AboutUsPage .contentText .text9 {
    width: 12.01rem;
    margin-top: 5.7rem;
    /* margin-left: -6.5rem; */
}

.AboutUsPage .contentText .text10 {
    width: 11.85rem;
    margin-top: .4rem;
    /* margin-left: -6.5rem; */
}

.AboutUsPage .contentText .secondbottom {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    margin-top: 2.3rem;
    width: 56%;
    /* margin-left: 7rem; */
    transform: scale(.9);
}

.AboutUsPage .secondbottom .textBottom {
    font-size: .24rem;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: .28rem;
    -webkit-background-clip: text;
    opacity: .8;
}

.AboutUsPage .secondbottom .textbottom1 {

}

.AboutUsPage .secondbottom .textbottom2 {
    margin-top: .05rem;
}

.AboutUsPage .secondbottom .textbottom3 {
    margin-top: .5rem;
}

.AboutUsPage .secondbottom .textbottom4 {
    margin-top: .1rem;
}

.AboutUsPage .secondbottom .bottomLine {
    background-color: #FFFFFF;
    opacity: .5;
    width: 100%;
    height: .01rem;
    margin-top: .5rem;
}

.AboutUsPage .viewQRCode {
    position: absolute;
    right: 0;
    top: -.19rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.AboutUsPage .viewQRCode .viewdivImage {
    height: 1.55rem;
    width: 1.55rem;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.AboutUsPage .viewQRCode .imgQRCode {
    height: 1.44rem;
    width: 1.44rem;
    position: relative;
}

.AboutUsPage .viewQRCode .textQRCode {
    font-size: .22rem;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: .26rem;
    -webkit-background-clip: text;
    margin-top: .15rem;
    opacity: .8;
}

.AboutUsPage .secondbottom .footerSection {
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: .28rem;
}

.AboutUsPage .secondbottom .footerText2 {
    margin-left: .1rem;
    text-align: center;
    font-size: .16rem;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.5);
}

.AboutUsPage .secondbottom .foothover {
    cursor: pointer;
}

.AboutUsPage .secondbottom .seperate {
    margin-left: .3rem;
    margin-right: .2rem;
    text-align: center;
    font-size: .14rem;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.5);
    line-height: .16rem;
}

.AboutUsPage .secondbottom .email {
    color: #86A1FF;
    margin-left: .03rem;
}

.AboutUsPage .contentText .textClickCN {
    /* font-family: PingFang SC-Semibold, PingFang SC; */
    font-weight: 600;
    text-decoration: none;
    border-bottom: 2px solid;
    margin-left: .05rem;
    margin-right: .05rem;
    cursor: pointer;
}

.AboutUsPage .contentText .textClickEN {
    font-family: Inter-BoldItalic, Inter;
    text-decoration: none;
    border-bottom: 2px solid;
    margin-left: .05rem;
    margin-right: .05rem;
    cursor: pointer;
}

.AboutUsPage .rightView {
    position: fixed;
    display:flex;
    right: 0;
    bottom: 30%;
}
