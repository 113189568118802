.View2_1 {
    width: 100%;
    height: 100%;
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    /* background-color: red; */
    left: 0;
}

.View2_1 .backImageContain {
    width: 100%;
    height: 100%;
    /* background-color: yellow; */
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all .5s;
}

.View2_1 .content {
    /* background-color: yellow; */
    /* width: 70%; */
    /* z-index: 10; */
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 0.2rem;
    flex: 1;
    transform: scale(1.0)
}

.View2_1 .backImage {
    top: 0;
    left: 0;
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    /* background-color: blue; */
}

.View2_1 .viewTitle {
    display: flex;
    position: relative;
    height: 1.22rem;
    width: 2.17rem;
    /* background-color: blue; */
    justify-content: center;
}

.View2_1 .imgTitle {
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: absolute;
}

.View2_1 .viewTitle .title {
    position: relative;
    height: .49rem;
    font-size: .35rem;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #000000;
    line-height: .41rem;
    -webkit-background-clip: text;
    z-index: 2;
    margin-top: .2rem;
}

.View2_1 .viewItem {
    display: flex;
    position: relative;
    margin-top: .15rem;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.View2_1 .viewRight {
    width: 6.11rem;
    margin-left: .0rem;
}


.View2_1 .viewImageItem {
    width: 6.5rem;
    height: 6.5rem;
    /* background-color: palevioletred; */
}

.View2_1 .imageItem {
    /* width: 100%;
    height: 100%; */
    width: 6.5rem;
    height: 6.5rem;
    object-fit: contain;
    position: absolute;
}

.View2_1 .textCHN {
    font-size: .22rem;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
    line-height: .49rem;
    letter-spacing: .03rem;
    -webkit-background-clip: text;
    text-align: left;
    /* margin-top: .5rem; */
}

.View2_1 .textENG {
    margin-top: .25rem;
    font-size: .22rem;
    font-family: Inter-Italic, Inter;
    font-weight: normal;
    color: #666666;
    line-height: .42rem;
    letter-spacing: .01rem;
    -webkit-background-clip: text;
    text-align: left;
}

.View2_1 .text1 {
    height: .7rem;
    width: 4.5rem;
    left: 2.2rem;
    top: .3rem;
    text-align: start;
}

.View2_1 .animation {
    transition: all .5s;
    opacity: 0;
    /* opacity: 1; */
}

.View2_1 .active {
    transition-delay: 0.8s;
    opacity: 1;
}

.View2_1 .viewMore {
    display: flex;
    position: absolute;
    top: 1.6rem;
    right: .2rem;
    z-index: 9;
    /* background-color: red; */
}

.View2_1 .rightView {
    position: absolute;
    display:flex;
    right: 0;
    bottom: 30%;
}