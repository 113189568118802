.viewbuttonMore {
    /* width: 100%; */
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex: 0;
    cursor: pointer;
}

.viewbuttonMore .left {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    width: .7rem;
    /* flex: 1; */
    /* background-color: yellow; */
}

.viewbuttonMore .viewright {
    height: .27rem;
    width: .34rem;
    display: flex;
    position: relative;
    margin-left: .1rem;
    /* background-color: red; */
}

.viewbuttonMore .imgright {
    height: .27rem;
    width: .34rem;
}

.viewbuttonMore .left .text {
    width: .64rem;
    height: .22rem;
    font-size: .16rem;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    line-height: .19rem;
    background: linear-gradient(113deg, #FFC6CD 0%, #E538FF 26%, #EF83FF 42%, #B716CF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: start;
    white-space: nowrap;
}

.viewbuttonMore .left .text1 {
    /* width: .49rem; */
    height: .28rem;
    font-size: .2rem;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    line-height: .23rem;
    background: linear-gradient(110deg, #FFA4AF 0%, #E42EFF 26%, #EF83FF 42%, #B716CF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.viewbuttonMore .left .textdiv1 {
    width: .49rem;
    height: .28rem;
}