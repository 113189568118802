.viewMenu {
  /* padding-top: 160rem; */
  height: 100%;
  width: 100%;
  /* background-color: blue; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}

.viewMenu::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, .95);
  background-size: cover;
  filter: blur(10px); /* 模糊程度 */
}

.viewMenu .viewMenutop{
  margin-top: 160rem;
}

.viewMenu .item {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  justify-content: space-between;
  /* background-color: green; */
  height: 85rem;
  width: 100%;
}

.viewMenu .item .textSelect {
  display: flex;
  font-size: 37rem;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #BD2AD3;
  line-height: 43rem;
  margin-left: 30rem;
}


.viewMenu .item .textUnSelect {
  display: flex;
  font-size: 37rem;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
  line-height: 43rem;
  margin-left: 30rem;
}

.viewMenu .item .imgUnSelect {
  display: flex;
  position: relative;
  height: 32rem;
  width: 20rem;
  /* background-color: black; */
  margin-right: 45rem;
}


.viewMenu .item .imgSelect {
  display: flex;
  position: relative;
  height: 32rem;
  width: 20rem;
  /* background-color: purple; */
  margin-right: 45rem;
}