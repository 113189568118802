.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 72px;

    box-shadow: 0px 4px 35px 0px rgba(225, 202, 228, 0.28);
    background: rgba(255, 255, 255, 0.06);
    backdrop-filter: blur(3px);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    /* padding-left: 350px;
    padding-right: 350px; */
    z-index: 1;
    border: 1px solid #FFFFFF;
    background-color: rgba(255, 255, 255, 0.85);
}

.headerLeftContainer .headerText {
    height: 24px;
    font-size: 22px;
    line-height: 24px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #191825;
    -webkit-background-clip: text;
    /* width: 320px; */
    margin-left: 16px;
}

.header .headerLeftContainer{
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.header .headerIcon {
    height: 60px;
    width: 60px;
}

.viewTab {
    flex: 0;
    z-index: 100;
}

.viewTab .viewTabIn {
    /* background-color: aqua; */
    width: '100%';
    height: '100%';
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.viewTabIn .textUnSelect {
    font-size: 18px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #666666;
    line-height: 22px;
    -webkit-background-clip: text;
    flex: 1;
    text-wrap: nowrap;
    margin-left: 16px;
    margin-right: 16px;
}

.viewTabIn .textSelect {
    font-size: 18px;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #BD2AD3;
    line-height: 22px;
    -webkit-background-clip: text;
    flex: 1;
    text-wrap: nowrap;
    margin-left: 16px;
    margin-right: 16px;
    z-index: 100;
    text-align: center;
}

.viewTabIn .viewTabIn2 {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    height: 62px;
    white-space: nowrap;
    cursor: pointer;
}

.viewTab .imgSelect{
    height: .62rem;
    width: .76rem;
    position: absolute;
    background-size: cover;
    z-index: 0;
    /* align-self: center; */
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}