.PrivatePolicyPage {
    display: flex;
    position: relative;
    width: 100%;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.PrivatePolicyPage .contentText {
    display: flex;
    position: relative;
    margin-top: .8rem;
    width: 100%;
    flex-direction: column;
    align-items: center;

}

.PrivatePolicyPage .contentText .contentTextStyle{
    width: 50%;
    text-wrap: wrap;
    overflow-wrap: break-word;
    font-size: .22rem;
    font-family: Poppins-Regular, Poppins;

    color: #000000;
    letter-spacing: .01rem;
    text-align: left;
    margin-top: 0.5rem;
    margin-bottom: .5rem;;
}

.PrivatePolicyPage .contentTextStyle .title {
    font-size: .4rem;
}

.PrivatePolicyPage .contentTextStyle .titleline {
    height: .01rem;
    background-color: gray;
    margin-top: .3rem;
    opacity: .3;
}

.PrivatePolicyPage .contentTextStyle .subtitle {
    font-size: .3rem;
    margin-top: .5rem;
}

.PrivatePolicyPage .contentTextStyle .content {
    font-size: .2rem;
    margin-top: .2rem;
    color: rgba(0, 0, 0, .75);
}

.PrivatePolicyPage .contentTextStyle .email {
    color: #86A1FF;
    margin-left: .03rem;
}

.PrivatePolicyPage .divBack {
    height: .4rem;
    width: .4rem;
    display: flex;
    position: relative;
    margin-left: -1rem;
    margin-top: -.45rem;
    cursor: pointer;
}

.PrivatePolicyPage .divBack .foothover {
    cursor: pointer;
}


.PrivatePolicyPage .divBack .imgBack{
    height: .35rem;
    width: .35rem;
}