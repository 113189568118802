.viewCISCHistoryPage {
  background-color: transparent;
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #F7F8FA;

}

.viewCISCHistoryPage .bktop {
  width: 100%;
  height: 345rem;
  /* background: linear-gradient( 103deg, #F5D7FF 0%, #FFE1E1 100%); */
  /* border-radius: 0px 0px 0px 0px; */
  /* border-radius: 0rem; */
  overflow: visible;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: -20rem;
  /* opacity: 0.5; */
  /* z-index: -1; */
}

.viewCISCHistoryPage .bktop .viewTop {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: -280rem;
  z-index: 1;
  height: 60rem;
  width: 100%;
  /* cursor: pointer; */
}

/* .viewCISCHistoryPage .bktop .viewTop .foothover {
  cursor: pointer;
} */

.viewCISCHistoryPage .bktop .viewTop .left {
  height: 60rem;
  width: 60rem;
  margin-left: 12rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
}

.viewCISCHistoryPage .bktop .viewTop .left .imageBack {
  height: 60rem;
  width: 60rem;
  object-fit: contain;
  align-self: center;
  display: flex;
  position: relative;
}

.viewCISCHistoryPage .bktop .viewTop .right {
  height: 60rem;
  width: 60rem;
  margin-right: 12rem;
}

.viewCISCHistoryPage .bktop .viewTop .title {
  font-family: PingFang SC, PingFang SC;
font-weight: 600;
font-size: 40rem;
color: #000000;
line-height: 47rem;
}


.viewCISCHistoryPage .bktop .imageHeader {
  display: flex;
  position: relative;
  width: 100%;
  height: 345rem;
  margin-top: -20rem;
}


.viewCISCHistoryPage .viewlist {
  display: flex;
  position: relative;
  background-color: #F7F8FA;
  margin-top: -135rem;
  flex: 1;
  width: 100%;
  margin-bottom: 10rem;
  /* height: 80%; */
  border-top-left-radius: 40rem;
  border-top-right-radius: 40rem;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 30rem;
  overflow: scroll;
}

.viewCISCHistoryPage .viewlist .textNodata {
  text-align: center;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 34rem;
  color: #8D8D8D;
  /* line-height: 60px; */
  letter-spacing: 1rem;
  text-align: justified;
  font-style: normal;
  text-transform: none;
  margin-top: 450rem;
}

.viewCISCHistoryPage .viewlist .viewItem {
  display: flex;
  position: relative;
  /* height: 178rem; */
  width: 690rem;
  margin-top: 15rem;
  margin-bottom: 15rem;
}

.viewCISCHistoryPage .viewlist .viewItem .imageList{
  display: flex;
  position: relative;
  height: 178rem;
  width: 690rem;
}

.viewCISCHistoryPage .viewlist .viewItem .textResult {
  font-size: 36rem;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  color: #000000;
  line-height: 42rem;
  position: absolute;
  top: 26rem;
  left: 100rem;
  text-align: left;
  font-style: normal;
  text-transform: none;
}

.viewCISCHistoryPage .viewfooter {
  display: flex;
  position: relative;
  /* margin-top: 160rem; */
  margin-bottom: 50rem;
  height: 116rem;
  width: 465rem;
  align-self: center;
}

.viewCISCHistoryPage .viewfooter .imagefooter {
  height: 116rem;
  width: 465rem;
}

.viewCISCHistoryPage .viewStart {
  display: flex;
  position: relative;
  width: 538rem;
  height: 88rem;
  margin-top: 110rem;
}

.viewCISCHistoryPage .viewStart .imagestart {
  width: 538rem;
  height: 88rem;
}



.viewCISCHistoryPage .textStart {
  font-size: 36rem;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
  line-height: 56rem;
  position: absolute;
  bottom: 50rem;
  width: 100%;
}
