.ContactPage {
    display: flex;
    position: relative;
    width: 100%;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.ContactPage .contentText {
    display: flex;
    position: relative;
    margin-top: .8rem;
    width: 100%;
    background-color: orchid;
    flex-direction: column;
    align-items: center;
}

.contentText .contentTextStyle{
    width: 50%;
    text-wrap: wrap;
    overflow-wrap: break-word;
}