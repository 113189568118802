.ViewContactRightMobile {
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 104rem;
    height: 104rem;
    background: linear-gradient(140deg, #FFC6CD 0%, #F3A5FF 34%, #EF83FF 57%, #B716CF 100%);
    border-radius: 52px 52px 52px 52px;
    opacity: 1;
    bottom: 200rem;
    right: 40rem;
}

.ViewContactRightMobile .contactimg {
    display: flex;
    position: relative;
    width: 52rem;
    height: 52rem;
}