
.WXmobileContactUs {
  display: flex;
  position: relative;
  width: 100%;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.WXmobileContactUs .divImageBack {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  object-fit: cover;
}

.WXmobileContactUs .divImageBack .backimgonediv {
  display: flex;
  position: relative;
  width: 100%;
  /* height: 100%; */
  object-fit: contain;
  margin-top: -70rem;
}

.WXmobileContactUs .divImageBack .backimgonediv .backimgone {
  width: 100%;
  /* height: 100%; */
  object-fit: contain;
}

.WXmobileContactUs .contentText {
  display: flex;
  position: relative;
  width: 100%;
  /* background-color: blue; */
  flex-direction: column;
  align-items: center;
  justify-content: start;
}

.WXmobileContactUs .contentText .title {
  font-size: 48rem;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
  line-height: 56rem;
  letter-spacing: 1rem;
  margin-top: 105rem;
}

.WXmobileContactUs .contentText .textCN {
  font-size: 30rem;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 65rem;
  letter-spacing: 2rem;
  -webkit-background-clip: text;
  text-align: left;
}

.WXmobileContactUs .contentText .textEN {
  font-size: 32rem;
  font-family: PingFang SC-Regular, PingFang SC;
  color: #616161;
  line-height: 62rem;
  -webkit-background-clip: text;
  text-align: left;
}

.WXmobileContactUs .contentText .textSemibold {
  font-weight: 600;
}

.WXmobileContactUs .contentText .textENSemibold {
  font-weight: 600;
  color: #181818;
}

.WXmobileContactUs .contentText .text1 {
  width: 670rem;
  margin-top: 135rem;
  margin-left: 39rem;
line-height: 47rem;
}

.WXmobileContactUs .contentText .text2 {
  width: 669rem;
  margin-top: 20rem;
  margin-left: 39rem;
  line-height: 47rem;
}

.WXmobileContactUs .contentText .text3 {
  width: 669rem;
  margin-top: 720rem;
  margin-left: 10rem;
}

.WXmobileContactUs .contentText .text4 {
  width: 626rem;
  margin-top: 737rem;
  margin-left: 31rem;
  line-height: 60rem;

}

.WXmobileContactUs .contentText .text5 {
  width: 622rem;
  margin-top: 16rem;
  margin-left: 31rem;
  line-height: 54rem;
}

.WXmobileContactUs .contentText .text6 {
  width: 626rem;
  margin-top: 471rem;
  margin-left: 31rem;
  line-height: 60rem;
}

.WXmobileContactUs .contentText .text7 {
  width: 622rem;
  margin-top: 16rem;
  margin-left: 31rem;
  line-height: 54rem;
}

.WXmobileContactUs .contentText .text8 {
  width: 626rem;
  margin-top: 491rem;
  margin-left: 31rem;
  line-height: 60rem;
}

.WXmobileContactUs .contentText .text81 {
  width: 622rem;
  margin-top: 16rem;
  margin-left: 31rem;
  line-height: 54rem;
}

.WXmobileContactUs .contentText .textHowToContact {
  font-size: 42rem;
  font-family: Inter-Bold, Inter;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 49rem;
  letter-spacing: 1rem;
  -webkit-background-clip: text;
  margin-top: 598rem;
}

.WXmobileContactUs .contentText .textCN2 {
  font-size: 30rem;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 65rem;
  letter-spacing: 1rem;
  -webkit-background-clip: text;
  text-align: left;
}

.WXmobileContactUs .contentText .textEN2 {
  font-size: 32rem;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 62rem;
  letter-spacing: 1rem;
  -webkit-background-clip: text;
  text-align: left;
}

.WXmobileContactUs .contentText .text9 {
  width: 614rem;
  margin-top: 98rem;
  /* margin-left: -6.5rem; */
}

.WXmobileContactUs .contentText .text10 {
  width: 621rem;
  margin-top: 46rem;
  /* margin-left: -6.5rem; */
}

.WXmobileContactUs .contentText .secondbottom {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -170rem;
  width: 100%;
  /* margin-left: 7rem; */
  /* transform: scale(.9); */
}

.WXmobileContactUs .secondbottom .textBottom {
  font-size: 28rem;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 33rem;
  -webkit-background-clip: text;
  opacity: .8;
}

.WXmobileContactUs .secondbottom .textbottom1 {}

.WXmobileContactUs .secondbottom .textbottom2 {
  margin-top: 5rem;
}

.WXmobileContactUs .secondbottom .textbottom3 {
  margin-top: 372rem;
}

.WXmobileContactUs .secondbottom .textbottom4 {
  margin-top: 34rem;
  height: 92rem;
  line-height: 46rem;
  width: 582rem;
}

.WXmobileContactUs .secondbottom .bottomLine {
  background-color: #FFFFFF;
  opacity: .5;
  width: 100%;
  height: .01rem;
  margin-top: .5rem;
}

.WXmobileContactUs .viewQRCode {
  position: relative;
  margin-top: 69rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.WXmobileContactUs .viewQRCode .viewdivImage {
  height: 297rem;
  width: 297rem;
  /* background-color: white; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.WXmobileContactUs .viewQRCode .imgQRCode {
  height: 297rem;
  width: 297rem;
  position: relative;
}

.WXmobileContactUs .viewQRCode .textQRCode {
  font-size: 28rem;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 33rem;
  letter-spacing: 3rem;
  margin-top: 15rem;
  opacity: .8;
  margin-bottom: 38rem;
}

.WXmobileContactUs .secondbottom .footerSection {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: .28rem;
}

.WXmobileContactUs .secondbottom .footerText2 {
  text-align: center;
  font-size: 24rem;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 28rem;
  -webkit-background-clip: text;
  opacity: .5;
  margin-top: 26rem;
  width: 650rem;
  cursor: pointer;
}

.WXmobileContactUs .secondbottom .foothover {
  cursor: pointer;
}

.WXmobileContactUs .secondbottom .seperate {
  margin-left: .3rem;
  margin-right: .2rem;
  text-align: center;
  font-size: .14rem;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.5);
  line-height: .16rem;
}

.WXmobileContactUs .secondbottom .email {
  color: #86A1FF;
  margin-left: 3rem;
}

.WXmobileContactUs .contentText .textClickCN {
  /* font-family: PingFang SC-Semibold, PingFang SC; */
  font-weight: 600;
  text-decoration: none;
  border-bottom: 2px solid;
  margin-left: 5rem;
  margin-right: 5rem;
  cursor: pointer;
}

.WXmobileContactUs .contentText .textClickEN {
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  text-decoration: none;
  border-bottom: 2px solid;
  margin-left: 5rem;
  margin-right: 5rem;
  cursor: pointer;
}

.WXmobileContactUs .rightView {
  position: fixed;
  display: flex;
  right: 0;
  bottom: 30%;
}

.WXmobileContactUs .secondbottom .footerIconEmail {
  display: flex;
  position: absolute;
  width: 26rem;
  height: 26rem;
  top: 374rem;
  left: 96rem;
}
.WXmobileContactUs .secondbottom .footerIconAddress {
  display: flex;
  position: absolute;
  width: 26rem;
  height: 26rem;
  top: 448rem;
  left: 50rem;
}

.WXmobileContactUs .viewContentWhite {
  /* background-color: red; */
  display: flex;
  position: relative;
  flex: 1;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  /* z-index: 2; */
}

.WXmobileContactUs .SectionName {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: start;
  margin-left: 40rem;
  margin-right: 40rem;
}

.WXmobileContactUs .SectionName .SectionNameIn {
  display: flex;
  flex-direction: row;
}

.WXmobileContactUs .sectionLeft {
  flex-direction: column;
  display: flex;
  position: relative;
  align-items: start;
  justify-content: center;
}

.WXmobileContactUs .textName {
  font-size: 30rem;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 35rem;
  text-align: start;
  height: 42rem;
}

.WXmobileContactUs .textStar {
  font-size: 32rem;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #FF9900;
  line-height: 32rem;
}

.WXmobileContactUs .anotherStar {
  position: absolute;
  bottom: 40rem;
  right: -15rem;
}

.WXmobileContactUs .textNameEN {
  font-size: 26rem;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #8A8A8A;
  line-height: 30rem;
  -webkit-background-clip: text;
  text-align: start;
  margin-top: 6rem;
  height: 32rem;
}

.WXmobileContactUs .ViewContactFormViewMobile {
  /* width: 100%; */
  /* height: 100%; */
  /* background-color: yellow; */
  display: flex;
  position: relative;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  margin-top: 140rem;
  width: 100%;
}

.WXmobileContactUs .ways {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  background-color: #F9F9F9;
  border-radius: 20rem 20rem 20rem 20rem;
  opacity: 1;
  border: 1rem solid #E4E4E4;
  width: 660rem;
  margin-top: 20rem;
  margin-bottom: 42rem;
  padding-top: 21rem;
  padding-bottom: 21rem;
}

.WXmobileContactUs .waysError {
  border: 1rem solid #dc3545;
}

.WXmobileContactUs .ways .way {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  margin-top: 15rem;
  margin-bottom: 15rem;
  margin-left: 24rem;
}

.WXmobileContactUs .ways .way .pot {
  display: flex;
  position: relative;
  height: 30rem;
  width: 30rem;
  background-color: #BD2AD3;
  border: 1px solid #FFC700;
  border-radius: 20rem;
}

.WXmobileContactUs .ways .way .potUnSelect {
  display: flex;
  position: relative;
  height: 30rem;
  width: 30rem;
  background-color: #F9F9F9;
  border: 1px solid #E4E4E4;
  border-radius: 20rem;
}

.WXmobileContactUs .ways .way .textCN {
  font-size: 29rem;
  font-weight: 400;
  color: #000000;
  line-height: 38rem;
  margin-left: 10rem;
}

.WXmobileContactUs .ways .way .textEN {
  font-size: 32rem;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 38rem;
  margin-left: 10rem;
}

.WXmobileContactUs .ways .way .textError {
  color: #dc3545;
}

.WXmobileContactUs .viewContactUs {
  width: 350rem;
  height: 90rem;
  background-color: #D05EE1;
  box-shadow: 0px 11px 29px 0px rgba(189, 42, 211, 0.38);
  border-radius: 45rem 45rem 45rem 45rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 94rem;
  position: relative;
  /* margin-bottom: .3rem; */
}

.WXmobileContactUs .viewDivContactUs {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 60rem;
  margin-top: 64rem;
  cursor: pointer;
}

.WXmobileContactUs .textContactUs {
  font-size: 36rem;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 42rem;
  letter-spacing: 1rem;
  -webkit-background-clip: text;
}

.WXmobileContactUs .contentText .title1 {
  font-size: 48rem;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
  line-height: 56rem;
  margin-top: 199rem;
}

.WXmobileContactUs .MuiTextField-root .MuiOutlinedInput-notchedOutline {
  border-radius: 20rem 20rem 20rem 20rem;
  opacity: 1;
  border: 1rem solid #E4E4E4;
}

.WXmobileContactUs .viewDonate {
  margin-top: 63rem;
}