.viewMobileHome {
  display: flex;
  position: relative;
  width: 100%;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.viewMobileHome .container {
  display: flex;
  position: relative;
  flex-direction: column;
}

.viewMobileHome .container .viewhome {}

.viewMobileHome .divImageBack {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  object-fit: cover;
}

.viewMobileHome .divImageBack .backimgonediv {
  display: flex;
  position: relative;
  width: 100%;
  /* height: 100%; */
  object-fit: contain;
}

.viewMobileHome .divImageBack .backimgonediv .backimgone {
  width: 100%;
  /* height: 100%; */
  object-fit: contain;
}

.viewMobileHome .imgdivcontact {
  height: 210rem;
  width: 312rem;
  object-fit: contain;
  display: flex;
  position: absolute;
  top: 54rem;
  left: 212rem;
}

.viewMobileHome .imgdivcontact .imgcontact {
  height: 210rem;
  width: 312rem;
  object-fit: contain;
}

.viewMobileHome .container .purplepot {
  width: 500rem;
  height: 500rem;
  background-color: rgba(189, 42, 211, 1);
  border-radius: 250rem 250rem 250rem 250rem;
  filter: blur(503rem);
  position: absolute;
  top: 0;
  left: 0;
}

.viewMobileHome .contentText {
  display: flex;
  position: relative;
  width: 100%;
  /* background-color: blue; */
  flex-direction: column;
  align-items: start;
  justify-content: start;
  /* flex: 0; */
  /* padding-left: 40rem;
  padding-right: 40rem; */
}

.viewMobileHome .contentText .headerCN {
  height: 67rem;
  font-size: 48rem;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
  line-height: 56rem;
  -webkit-background-clip: text;
  margin-top: 138rem;
  margin-bottom: 21rem;
  margin-left: 40rem;

}

.viewMobileHome .contentText .headerEN {
  height: 56rem;
  font-size: 37rem;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #181818;
  line-height: 43rem;
  -webkit-background-clip: text;
  margin-left: 40rem;

}

.viewMobileHome .contentText .purpleText {
  color: #BD2AD3;
  margin-left: 0rem;
}

.viewMobileHome .contentText .title {
  font-size: 48rem;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
  line-height: 56rem;
  letter-spacing: 1rem;
  margin-top: 64rem;
  width: 100%;
}

.viewMobileHome .contentText .title11 {
  margin-left: 238rem;
}

.viewMobileHome .contentText .titlemission {
  margin-top: 130rem;
}

.viewMobileHome .contentText .titlesupport {
  margin-top: 96rem;
}

.viewMobileHome .contentText .titledonate {
  margin-top: 70rem;
}

.viewMobileHome .contentText .titlecontact {
  margin-top: 86rem;
}

.viewMobileHome .viewhome .text11 {
  font-size: 34rem;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #181818;
  line-height: 69rem;
  letter-spacing: 1rem;
  -webkit-background-clip: text;
  text-align: left;
  /* margin-right: 40rem; */
  /* width: 669rem; */
  margin-top: 666rem;
  margin-left: 40rem;
  margin-right: 40rem;
}

.viewMobileHome .viewhome .text22 {
  font-size: 34rem;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 56rem;
  -webkit-background-clip: text;
  text-align: left;
  margin-top: 27rem;
  width: 669rem;
  margin-left: 40rem;
  margin-right: 40rem;
}

.viewMobileHome .viewhome .buttoncontact {
  margin-left: 200rem;
  margin-top: 60rem;
  width: 350rem;
  height: 90rem;
  background-color: #D05EE1;
  box-shadow: 0px 11px 29px 0px rgba(189, 42, 211, 0.38);
  border-radius: 45rem 45rem 45rem 45rem;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.viewMobileHome .viewhome .buttoncontact .textcontact {
  display: flex;
  font-size: 36rem;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 42rem;
  letter-spacing: 1rem;
  -webkit-background-clip: text;
}

.viewMobileHome .contentText .buttonmore {
  width: 328rem;
  height: 82rem;
  background: linear-gradient(114deg, #FFC6CD 0%, #F3A5FF 26%, #EF83FF 42%, #B716CF 100%);
  border-radius: 44rem 44rem 44rem 44rem;
  opacity: 1;
  border: 1px solid #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 214rem;
  margin-top: 37rem;
}

.viewMobileHome .viewourmission .buttonmore {
  margin-top: 162rem;
}

.viewMobileHome .viewsupport .buttonmore {
  margin-top: 156rem;

}

.viewMobileHome .contentText .buttonmore .textmore {
  font-size: 28rem;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 33rem;
  -webkit-background-clip: text;
}

.viewMobileHome .contentText .buttonmore .textMoreEN {
  font-size: 28rem;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 33rem;
  letter-spacing: 3rem;
  margin-left: 10rem;
  -webkit-background-clip: text;
}

.viewMobileHome .contentText .textCN {
  font-size: 30rem;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 65rem;
  letter-spacing: 1rem;
  -webkit-background-clip: text;
  text-align: left;
}

.viewMobileHome .contentText .textEN {
  font-size: 32rem;
  font-family: PingFang SC-Regular, PingFang SC;
  color: #616161;
  line-height: 62rem;
  -webkit-background-clip: text;
  text-align: left;
  letter-spacing: 2rem;
}

.viewMobileHome .contentText .textSemibold {
  font-weight: 600;
}

.viewMobileHome .contentText .textENSemibold {
  font-weight: 600;
  color: #181818;
}

.viewMobileHome .contentText .text1 {
  margin-left: 40rem;
  margin-right: 40rem;
  margin-top: 127rem;
}

.viewMobileHome .contentText .text2 {
  margin-left: 40rem;
  margin-right: 40rem;
  margin-top: 818rem;
}

.viewMobileHome .contentText .text3 {
  margin-left: 70rem;
  margin-right: 70rem;
  margin-top: 460rem;
}

.viewMobileHome .contentText .text4 {
  margin-left: 70rem;
  margin-right: 70rem;
  margin-top: 16rem;
  letter-spacing: 1rem;

}

.viewMobileHome .contentText .text5 {
  margin-left: 70rem;
  margin-right: 70rem;
  margin-top: 515rem;
}

.viewMobileHome .contentText .text6 {
  margin-left: 70rem;
  margin-right: 50rem;
  margin-top: 16rem;
  letter-spacing: 1rem;
}

.viewMobileHome .contentText .text7 {
  margin-left: 70rem;
  margin-right: 70rem;
  margin-top: 436rem;
}

.viewMobileHome .contentText .text8 {
  margin-left: 70rem;
  margin-right: 70rem;
  margin-top: 16rem;
  letter-spacing: 1rem;
}

.viewMobileHome .viewsupport .textssCN {
  font-size: 40rem;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 46rem;
  letter-spacing: 4rem;
  text-shadow: 1px 1px 8px rgba(0, 0, 0, 0.77);
  -webkit-background-clip: text;
  width: 100%;
}

.viewMobileHome .viewsupport .textssEN {
  font-size: 34rem;
  font-family: Inter-BoldItalic, Inter;
  font-weight: normal;
  color: rgba(255, 255, 255, 0.94);
  line-height: 52rem;
  letter-spacing: 2rem;
  text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.59);
  -webkit-background-clip: text;
  width: 100%;
}

.viewMobileHome .viewsupport .textss1 {
  margin-top: 396rem;
}

.viewMobileHome .viewsupport .textss2 {
  margin-top: 42rem;
}

.viewMobileHome .viewsupport .textss3 {
  margin-top: 408rem;
}

.viewMobileHome .viewsupport .textss4 {
  margin-top: 42rem;
}

.viewMobileHome .viewsupport .textss5 {
  margin-top: 408rem;
}

.viewMobileHome .viewsupport .textss6 {
  margin-top: 42rem;
}

.viewMobileHome .viewsupport .textss7 {
  margin-top: 408rem;
}

.viewMobileHome .viewsupport .textss8 {
  margin-top: 42rem;
}


.viewMobileHome .contentText .text81 {
  width: 622rem;
  margin-top: 16rem;
  margin-left: 31rem;
  line-height: 54rem;
}

.viewMobileHome .contentText .textdtEN {
  font-size: 30rem;
  font-family: Inter-Italic, Inter;
  font-weight: 400;
  color: #666666;
  line-height: 46rem;
  -webkit-background-clip: text;
  text-align: left;
}

.viewMobileHome .contentText .textdt1 {
  margin-left: 40rem;
  margin-right: 40rem;
  margin-top: 135rem;
  line-height: 47rem;
}

.viewMobileHome .contentText .textdt2 {
  margin-left: 40rem;
  margin-right: 40rem;
  margin-top: 20rem;
}

.viewMobileHome .contentText .textHowToContact {
  font-size: 42rem;
  font-family: Inter-Bold, Inter;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 49rem;
  letter-spacing: 1rem;
  -webkit-background-clip: text;
  margin-top: 598rem;
}

.viewMobileHome .contentText .textCN2 {
  font-size: 30rem;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 65rem;
  letter-spacing: 1rem;
  -webkit-background-clip: text;
  text-align: left;
}

.viewMobileHome .contentText .textEN2 {
  font-size: 32rem;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 62rem;
  letter-spacing: 1rem;
  -webkit-background-clip: text;
  text-align: left;
}

.viewMobileHome .contentText .text9 {
  width: 614rem;
  margin-top: 98rem;
  /* margin-left: -6.5rem; */
}

.viewMobileHome .contentText .text10 {
  width: 621rem;
  margin-top: 46rem;
  /* margin-left: -6.5rem; */
}

.viewMobileHome .viewhomebottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.viewMobileHome .viewhomebottom .textBottom {
  font-size: 28rem;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 33rem;
  -webkit-background-clip: text;
  opacity: .8;
}

.viewMobileHome .viewhomebottom .textbottom1 {}

.viewMobileHome .viewhomebottom .textbottom2 {
  margin-top: 5rem;
}

.viewMobileHome .viewhomebottom .textbottom3 {
  /* margin-top: 60rem; */
}

.viewMobileHome .viewhomebottom .textbottom4 {
  margin-top: 34rem;
  height: 92rem;
  line-height: 46rem;
  margin-bottom: 35rem;
  margin-left: 60rem;
  margin-right: 66rem;
}

.viewMobileHome .viewhomebottom .bottomLine {
  background-color: #FFFFFF;
  opacity: .5;
  width: 100%;
  height: .01rem;
  margin-top: .5rem;
}

.viewMobileHome .viewQRCode {
  position: relative;
  margin-top: 40rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.viewMobileHome .viewQRCode .viewdivImage {
  height: 297rem;
  width: 297rem;
  /* background-color: white; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.viewMobileHome .viewQRCode .imgQRCode {
  height: 297rem;
  width: 297rem;
  position: relative;
}

.viewMobileHome .viewQRCode .textQRCode {
  font-size: 28rem;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 42rem;
  letter-spacing: 3rem;
  -webkit-background-clip: text;
  margin-bottom: 60rem;
}

.viewMobileHome .viewQRCode .textQRCodeTitle {
  font-size: 30rem;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
  line-height: 42rem;
  -webkit-background-clip: text;
  margin-bottom: 20rem;
}

.viewMobileHome .viewhomebottom .footerSection {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: .28rem;
}

.viewMobileHome .viewhomebottom .footerText2 {
  text-align: center;
  font-size: 24rem;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 28rem;
  -webkit-background-clip: text;
  opacity: .5;
  margin-top: 26rem;
  width: 650rem;
  cursor: pointer;
}

.viewMobileHome .viewhomebottom .foothover {
  cursor: pointer;
}

.viewMobileHome .viewhomebottom .seperate {
  margin-left: .3rem;
  margin-right: .2rem;
  text-align: center;
  font-size: .14rem;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.5);
  line-height: .16rem;
}

.viewMobileHome .viewhomebottom .email {
  color: #86A1FF;
  margin-left: 3rem;
}

.viewMobileHome .contentText .textClickCN {
  /* font-family: PingFang SC-Semibold, PingFang SC; */
  font-weight: 600;
  text-decoration: none;
  border-bottom: 2px solid;
  margin-left: 5rem;
  margin-right: 5rem;
  cursor: pointer;
}

.viewMobileHome .contentText .textClickEN {
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  text-decoration: none;
  border-bottom: 2px solid;
  margin-left: 5rem;
  margin-right: 5rem;
  cursor: pointer;
}

.viewMobileHome .rightView {
  position: fixed;
  display: flex;
  right: 0;
  bottom: 30%;
}

.viewMobileHome .viewhomebottom .footerIconEmail {
  display: flex;
  position: absolute;
  width: 26rem;
  height: 26rem;
  top: 78rem;
  left: 98rem;
}

.viewMobileHome .viewhomebottom .footerIconAddress {
  display: flex;
  position: absolute;
  width: 26rem;
  height: 26rem;
  top: 150rem;
  left: 55rem;
}

.viewMobileHome .viewContentWhite {
  /* background-color: red; */
  display: flex;
  position: relative;
  flex: 1;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  /* z-index: 2; */
}

.viewMobileHome .SectionName {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: start;
  margin-left: 40rem;
  margin-right: 40rem;
}

.viewMobileHome .SectionName .SectionNameIn {
  display: flex;
  flex-direction: row;
}

.viewMobileHome .sectionLeft {
  flex-direction: column;
  display: flex;
  position: relative;
  align-items: start;
  justify-content: center;
}

.viewMobileHome .textName {
  font-size: 30rem;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 35rem;
  text-align: start;
}

.viewMobileHome .textStar {
  font-size: 32rem;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #FF9900;
  line-height: 32rem;
}

.viewMobileHome .anotherStar {
  position: absolute;
  bottom: 40rem;
  right: -15rem;
}

.viewMobileHome .textNameEN {
  font-size: 26rem;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #8A8A8A;
  line-height: 30rem;
  -webkit-background-clip: text;
  text-align: start;
  margin-top: 6rem;
}

.viewMobileHome .ViewContactFormViewMobile {
  /* width: 100%; */
  /* height: 100%; */
  /* background-color: yellow; */
  display: flex;
  position: relative;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  margin-top: 200rem;
  width: 100%;
}

.viewMobileHome .ways {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  background-color: #F9F9F9;
  border-radius: 20rem 20rem 20rem 20rem;
  opacity: 1;
  border: 1rem solid #E4E4E4;
  width: 660rem;
  margin-top: 20rem;
  margin-bottom: 42rem;
  padding-top: 21rem;
  padding-bottom: 21rem;
}

.viewMobileHome .waysError {
  border: 1rem solid #dc3545;
}

.viewMobileHome .ways .way {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  margin-top: 15rem;
  margin-bottom: 15rem;
  margin-left: 24rem;
}

.viewMobileHome .ways .way .pot {
  display: flex;
  position: relative;
  height: 30rem;
  width: 30rem;
  background-color: #BD2AD3;
  border: 1px solid #FFC700;
  border-radius: 20rem;
}

.viewMobileHome .ways .way .potUnSelect {
  display: flex;
  position: relative;
  height: 30rem;
  width: 30rem;
  background-color: #F9F9F9;
  border: 1px solid #E4E4E4;
  border-radius: 20rem;
}

.viewMobileHome .ways .way .textCN {
  font-size: 29rem;
  font-weight: 400;
  color: #000000;
  line-height: 38rem;
  margin-left: 10rem;
}

.viewMobileHome .ways .way .textEN {
  font-size: 32rem;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 38rem;
  margin-left: 10rem;
}

.viewMobileHome .ways .way .textError {
  color: #dc3545;
}

.viewMobileHome .viewContactUs {
  width: 350rem;
  height: 90rem;
  background-color: #D05EE1;
  box-shadow: 0px 11px 29px 0px rgba(189, 42, 211, 0.38);
  border-radius: 45rem 45rem 45rem 45rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 94rem;
  position: relative;
  /* margin-bottom: .3rem; */
}

.viewMobileHome .viewDivContactUs {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 30rem;
  margin-top: 140rem;
  cursor: pointer;
}

.viewMobileHome .textContactUs {
  font-size: 36rem;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 42rem;
  letter-spacing: 1rem;
  -webkit-background-clip: text;
}

.viewMobileHome .contentText .title1 {
  font-size: 48rem;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
  line-height: 56rem;
  margin-top: 178rem;
}

.viewMobileHome .MuiTextField-root .MuiOutlinedInput-notchedOutline {
  border-radius: 20rem 20rem 20rem 20rem;
  opacity: 1;
  border: 1rem solid #E4E4E4;
}

.viewMobileHome .viewDonate {
  margin-top: 63rem;
  /* width: 100%; */
  margin-left: 160rem;
}