.StripeViewMobile {
    position: realative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* padding-top: .1rem; */
    padding-bottom: 10rem;
    padding-left: 20rem;
    padding-right: 20rem;
    text-align: center;
    width: 100%;
    flex: 1;
    /* background-color: green; */
}

.StripeViewMobile .StripeItemLast {
    width: 350rem;
    height: 90rem;
    background-color: #FFF1B9;
    box-shadow: 0px 5px 9px 0px rgba(123,123,123,0.38);
    border-radius: 45rem 45rem 45rem 45rem;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
}

.StripeViewMobile .StripeItemLasthover {
    width: 350rem;
    height: 90rem;
    background-color: #D05EE1;
    box-shadow: 0px 5px 9px 0px rgba(189,42,211,0.38);
    border-radius: 45rem 45rem 45rem 45rem;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
}

.StripeViewMobile .StripeItemhover {
    width: 350rem;
    height: 90rem;
    background-color: #D05EE1;
    box-shadow: 0px 5px 9px 0px rgba(189,42,211,0.38);
    border-radius: 45rem 45rem 45rem 45rem;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    color: #000000;
    margin-bottom: 43rem;
    cursor: pointer;

}

.StripeViewMobile .StripeItem {
    width: 350rem;
    height: 90rem;
    background-color: #FFF1B9;
    box-shadow: 0px 5px 9px 0px rgba(123,123,123,0.38);
    border-radius: 45rem 45rem 45rem 45rem;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 43rem;
    position: relative;
    color: #000000;
}

.StripeViewMobile .StripeText {
    font-size: 40rem;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 500;
    color: #000000;
    line-height: 47rem;
    -webkit-background-clip: text;
    width: 169rem;
}

.StripeViewMobile .StripeTexthover {
    font-size: 40rem;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 47rem;
    -webkit-background-clip: text;
    width: 169rem;
}

.StripeViewMobile .StripeTextLast {
    font-size: 40rem;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 47rem;
    -webkit-background-clip: text;
}

.StripeViewMobile .ArrayItem{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100%;

}