.ViewBottomfooter {
    position: realative;
    height: 1.36rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: .1rem;
    padding-bottom: .1rem;
    padding-left: .2rem;
    padding-right: .2rem;
    /* background-color: red; */
    /* background-color: gray; */
}

.ViewBottomfooter .footerIcon {
    display: flex;
    position: relative;
    width: .2rem;
    height: .2rem;
    /* display: block;
  margin: 0 auto; */
}

.ViewBottomfooter .footerText {
    display: flex;
    margin-left: .05rem;
    text-align: center;
    font-size: .16rem;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
}

.ViewBottomfooter .footerdivText {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.ViewBottomfooter .footerSection {
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: .16rem;
}

.ViewBottomfooter .footerText2 {
    margin-left: .1rem;
    text-align: center;
    font-size: .14rem;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.5);
}

.ViewBottomfooter .foothover {
    cursor: pointer;
}

.ViewBottomfooter .seperate {
    margin-left: .3rem;
    margin-right: .2rem;
    text-align: center;
    font-size: .14rem;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.5);
    line-height: .16rem;
}

.ViewBottomfooter .footerSection .email {
    color: #86A1FF;
    margin-left: .03rem;
}