.ViewSupport {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.ViewSupport .backImageContain {
    width: 100%;
    height: 100%;
    /* background-color: yellow; */
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    transition: all .5s;
}

.ViewSupport .content {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
}

.ViewSupport .backImage {
    top: 0;
    left: 0;
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    /* background-color: blue; */
    object-fit: fill;
}

.ViewSupport .viewTitle {
    display: flex;
    position: relative;
    height: 1.24rem;
    width: 3.37rem;
    /* background-color: blue; */
    justify-content: center;
    margin-top: .5rem;
}

.ViewSupport .imgTitle {
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: absolute;
}

.ViewSupport .viewTitle .title {
    position: relative;
    height: .49rem;
    font-size: .35rem;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #000000;
    line-height: .41rem;
    -webkit-background-clip: text;
    z-index: 2;
    margin-top: .2rem;
}

.ViewSupport .viewItem {
    display: flex;
    position: relative;
    margin-top: -.0rem;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.ViewSupport .viewRight {
    width: 6.6rem;
    margin-left: .3rem;
}

.ViewSupport .viewImageItem4 {
    width: 3.75rem;
    height: 6.4rem;
    display: flex;
    position: relative;
    margin-top: 1rem;
    margin-left: -0.25rem;
    margin-right: -0.25rem;
}
.ViewSupport .viewImageItem1 {
    width: 3.75rem;
    height: 6.4rem;
    display: flex;
    position: relative;
    margin-top: -1rem;
    margin-left: -0.25rem;
    margin-right: -0.25rem;
}
.ViewSupport .viewImageItem2 {
    width: 3.75rem;
    height: 6.4rem;
    display: flex;
    position: relative;
    margin-top: 1rem;
    margin-left: -0.25rem;
    margin-right: -0.25rem;
}
.ViewSupport .viewImageItem3 {
    width: 3.75rem;
    height: 6.4rem;
    display: flex;
    position: relative;
    margin-top: -1rem;
    margin-left: -0.25rem;
    margin-right: -0.25rem;
}

.ViewSupport .viewImageItem1 .imageItem1 {
    /* width: 100%;
    height: 100%; */
    width: 3.75rem;
    height: 6.4rem;
    object-fit: contain;
    position: absolute;
}

.ViewSupport .imageItem2 {
    /* width: 100%;
    height: 100%; */
    width: 3.75rem;
    height: 6.4rem;
    object-fit: contain;
    position: absolute;
}

.ViewSupport .imageItem3 {
    /* width: 100%;
    height: 100%; */
    width: 3.75rem;
    height: 6.4rem;
    object-fit: contain;
    position: absolute;
}

.ViewSupport .imageItem4 {
    /* width: 100%;
    height: 100%; */
    width: 3.75rem;
    height: 6.4rem;
    object-fit: contain;
    position: absolute;
}

.ViewSupport .textCHN {
    font-size: .22rem;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
    line-height: .49rem;
    letter-spacing: .01rem;
    -webkit-background-clip: text;
    text-align: left;
}

.ViewSupport .textENG {
    margin-top: .25rem;
    font-size: .22rem;
    ont-family: Inter-Italic, Inter;
    font-weight: normal;
    color: #666666;
    line-height: .42rem;
    letter-spacing: .01rem;
    -webkit-background-clip: text;
    text-align: left;
}

.ViewSupport .animation {
    transition: all .5s;
    opacity: 0;
    /* opacity: 1; */
}

.ViewSupport .active {
    transition-delay: 0.8s;
    opacity: 1;
}

.ViewSupport .viewMore {
    display: flex;
    position: absolute;
    bottom: 0.5rem;
    right: -4.3rem;
    z-index: 9;
    /* background-color: red; */
}

.ViewSupport .rightView {
    position: absolute;
    display:flex;
    right: 0;
    bottom: 30%;
}