.pagBox {
    position: fixed;
    bottom: .28rem;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    z-index: 2;
}

.pag {
    width: .08rem;
    height: .08rem;
    background: #EAEAEA;
    border-radius: .28rem;
    opacity: 1;
    border: 1px solid #FF9900;
    transition: all .5s;
    margin: 0 .06rem;
    cursor: pointer;
}

.pagActive {
    width: .24rem;
    background: #BD2AD3;
}