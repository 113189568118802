.viewMobilePP {
    display: flex;
    position: relative;
    width: 100%;
    /* height: 100%; */
    align-items: center;
    flex-direction: column;
    justify-content: center;
    /* background-color: #86A1FF; */
}

.viewMobilePP .contentText {
    display: flex;
    position: relative;
    margin-top: 80rem;
    width: 100%;
    flex-direction: column;
    align-items: center;

}

.viewMobilePP .contentText .contentTextStyle{
    width: 90%;
    text-wrap: wrap;
    overflow-wrap: break-word;
    font-size: 22rem;
    font-family: Poppins-Regular, Poppins;

    color: #000000;
    letter-spacing: 1rem;
    text-align: left;
    margin-top: 50rem;
    margin-bottom: 50rem;;
}

.viewMobilePP .contentTextStyle .title {
    font-size: 40rem;
}

.viewMobilePP .contentTextStyle .titleline {
    height: 1rem;
    background-color: gray;
    margin-top: 30rem;
    opacity: .3;
}

.viewMobilePP .contentTextStyle .subtitle {
    font-size: 34rem;
    margin-top: 50rem;
}

.viewMobilePP .contentTextStyle .content {
    font-size: 26rem;
    margin-top: 20rem;
    color: rgba(0, 0, 0, .75);
}

.viewMobilePP .contentTextStyle .email {
    color: #86A1FF;
    margin-left: 3rem;
}

.viewMobilePP .divBack {
    height: 40rem;
    width: 40rem;
    display: flex;
    position: relative;
    margin-right: 20rem;
}

.viewMobilePP .divBack .imgBack{
    height: 35rem;
    width: 35rem;
}

.viewMobilePP .contentText .headerview {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: start;
}