.viewMobileSupport {
  display: flex;
  position: relative;
  width: 100%;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.viewMobileSupport .divImageBack {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  object-fit: cover;
}

.viewMobileSupport .divImageBack .backimgonediv {
  display: flex;
  position: relative;
  width: 100%;
  /* height: 100%; */
  object-fit: contain;
}

.viewMobileSupport .divImageBack .backimgonediv .backimgone {
  width: 100%;
  /* height: 100%; */
  object-fit: contain;
}

.viewMobileSupport .contentText {
  display: flex;
  position: relative;
  width: 100%;
  /* background-color: blue; */
  flex-direction: column;
  align-items: center;
  justify-content: start;
}

.viewMobileSupport .contentText .title {
  font-size: 42rem;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
  line-height: 49rem;
  letter-spacing: 1rem;
  margin-top: 140rem;
}

.viewMobileSupport .contentText .textClickCN {
  /* font-family: PingFang SC-Semibold, PingFang SC; */
  font-weight: 600;
  text-decoration: none;
  border-bottom: 2px solid;
  margin-left: 5rem;
  margin-right: 5rem;
  cursor: pointer;
}

.viewMobileSupport .contentText .textClickEN {
  font-family: Inter-BoldItalic, Inter;
  text-decoration: none;
  border-bottom: 2px solid;
  margin-left: 5rem;
  margin-right: 5rem;
  cursor: pointer;
}

.viewMobileSupport .contentText .textCN {
  font-size: 30rem;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 65rem;
  letter-spacing: 2rem;
  -webkit-background-clip: text;
  text-align: left;
}

.viewMobileSupport .contentText .textEN {
  font-size: 32rem;
  font-family: PingFang SC-Regular, PingFang SC;
  color: #616161;
  line-height: 62rem;
  -webkit-background-clip: text;
  text-align: left;
}

.viewMobileSupport .contentText .textSemibold {
  font-weight: 600;
}

.viewMobileSupport .contentText .textENSemibold {
  font-weight: 600;
  color: #181818;
}

.viewMobileSupport .contentText .text1 {
  width: 669rem;
  margin-top: 833rem;
  margin-left: 16rem;
  height: 355rem;
}

.viewMobileSupport .contentText .text2 {
  width: 669rem;
  margin-top: 0rem;
  margin-left: 0rem;
  height: 622rem;
}

.viewMobileSupport .contentText .title1{
  margin-top: 224rem;
}

.viewMobileSupport .contentText .text3 {
  width: 669rem;
  margin-top: 900rem;
  margin-left: 16rem;
  height: 420rem;
}

.viewMobileSupport .contentText .text4 {
  width: 669rem;
  margin-top: 48rem;
  margin-left: 0rem;
  height: 744rem;
}

.viewMobileSupport .contentText .title2{
  font-size: 42rem;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
  line-height: 49rem;
  letter-spacing: 1rem;
  margin-top: 194rem;
  position: absolute;
  top: 4438rem;
}

.viewMobileSupport .contentText .text5 {
  width: 669rem;
  margin-top: 1280rem;
  margin-left: 16rem;
  height: 290rem;
}

.viewMobileSupport .contentText .text6 {
  width: 657rem;
  margin-top: 48rem;
  margin-left: 0rem;
  height: 560rem;
}

.viewMobileSupport .contentText .title3{
  /* margin-top: 250rem; */
  position: absolute;
  top: 6728rem;
}

.viewMobileSupport .contentText .text7 {
  width: 669rem;
  margin-top: 1200rem;
  margin-left: 16rem;
  height: 325rem;

}

.viewMobileSupport .contentText .text8 {
  width: 657rem;
  margin-top: 48rem;
  margin-left: 0rem;
  height: 560rem;
}

.viewMobileSupport .contentText .text81 {
  width: 622rem;
  margin-top: 16rem;
  margin-left: 31rem;
  line-height: 54rem;
}

.viewMobileSupport .contentText .textHowToContact {
  font-size: 42rem;
  font-family: Inter-Bold, Inter;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 49rem;
  letter-spacing: 1rem;
  -webkit-background-clip: text;
  margin-top: 270rem;
}

.viewMobileSupport .contentText .textCN2 {
  font-size: 30rem;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 65rem;
  letter-spacing: 1rem;
  -webkit-background-clip: text;
  text-align: left;
}

.viewMobileSupport .contentText .textEN2 {
  font-size: 32rem;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 62rem;
  letter-spacing: 1rem;
  -webkit-background-clip: text;
  text-align: left;
}

.viewMobileSupport .contentText .text9 {
  width: 614rem;
  margin-top: 98rem;
  /* margin-left: -6.5rem; */
}

.viewMobileSupport .contentText .text10 {
  width: 621rem;
  margin-top: 46rem;
  /* margin-left: -6.5rem; */
}

.viewMobileSupport .contentText .secondbottom {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 80rem;
  width: 100%;
  margin-left: 7rem;
  transform: scale(.9);
}

.viewMobileSupport .secondbottom .textBottom {
  font-size: 28rem;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 33rem;
  -webkit-background-clip: text;
  opacity: .8;
}

.viewMobileSupport .secondbottom .textbottom1 {}

.viewMobileSupport .secondbottom .textbottom2 {
  margin-top: 5rem;
}

.viewMobileSupport .secondbottom .textbottom3 {
  margin-top: 372rem;
}

.viewMobileSupport .secondbottom .textbottom4 {
  margin-top: 34rem;
  height: 92rem;
  line-height: 46rem;
  width: 582rem;
}

.viewMobileSupport .secondbottom .bottomLine {
  background-color: #FFFFFF;
  opacity: .5;
  width: 100%;
  height: .01rem;
  margin-top: .5rem;
}

.viewMobileSupport .viewQRCode {
  position: relative;
  margin-top: 69rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.viewMobileSupport .viewQRCode .viewdivImage {
  height: 297rem;
  width: 297rem;
  /* background-color: white; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.viewMobileSupport .viewQRCode .imgQRCode {
  height: 297rem;
  width: 297rem;
  position: relative;
}

.viewMobileSupport .viewQRCode .textQRCode {
  font-size: 28rem;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 33rem;
  letter-spacing: 3rem;
  margin-top: 15rem;
  opacity: .8;
  margin-bottom: 38rem;
}

.viewMobileSupport .secondbottom .footerSection {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: .28rem;
}

.viewMobileSupport .secondbottom .footerText2 {
  text-align: center;
  font-size: 24rem;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 28rem;
  -webkit-background-clip: text;
  opacity: .5;
  margin-top: 26rem;
  width: 650rem;
}

.viewMobileSupport .secondbottom .foothover {
  cursor: pointer;
}

.viewMobileSupport .secondbottom .seperate {
  margin-left: .3rem;
  margin-right: .2rem;
  text-align: center;
  font-size: .14rem;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.5);
  line-height: .16rem;
}

.viewMobileSupport .secondbottom .email {
  color: #86A1FF;
  margin-left: 3rem;
}

.viewMobileSupport .contentText .textClickCN {
  /* font-family: PingFang SC-Semibold, PingFang SC; */
  font-weight: 600;
  text-decoration: none;
  border-bottom: 2px solid;
  margin-left: 5rem;
  margin-right: 5rem;
  cursor: pointer;
}

.viewMobileSupport .contentText .textClickEN {
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  text-decoration: none;
  border-bottom: 2px solid;
  margin-left: 5rem;
  margin-right: 5rem;
  cursor: pointer;
}

.viewMobileSupport .rightView {
  position: fixed;
  display: flex;
  right: 0;
  bottom: 30%;
}


.viewMobileSupport .secondbottom .footerIconEmail {
  display: flex;
  position: absolute;
  width: 26rem;
  height: 26rem;
  top: 374rem;
  left: 96rem;
}
.viewMobileSupport .secondbottom .footerIconAddress {
  display: flex;
  position: absolute;
  width: 26rem;
  height: 26rem;
  top: 448rem;
  left: 50rem;
}