.View12box {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    align-items: center;
}


.View12box .itemBtn {
    transition: all .5s;
    width: .67rem;
    height: .67rem;
    background-color: #fff;
    position: absolute;
    z-index: 2; 
    border-radius: .34rem;
    opacity: 1;
    border: 2px solid rgba(255,255,255,0.15);
    box-shadow: 0px 4px 20px 0px rgba(220,168,228,0.72);
    display: flex;
    align-items: center;
    justify-content: center;
}

.View12box .imageArrow {
    display: flex;
    position: relative;
    height: .3rem;
    width: .18rem;
}

.View12box .itemLeft {
    left: 10vw;
}

.View12box .itemRight {
    right: 10vw;
}

.View12box .active {
    transition-delay: 0.8s;
    opacity: 1;
}