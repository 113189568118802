.viewFourTestMinePage {
  background-color: transparent;
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}

.viewFourTestMinePage .bktop {
  width: 100%;
  height: 100%;
  /* background-image: url("https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/phychotest/main/bk.png"); */
  /* background: linear-gradient( 103deg, #F5D7FF 0%, #FFE1E1 100%); */
  /* border-radius: 0px 0px 0px 0px; */
  border-radius: 0rem;
  overflow: visible;
  position: absolute;
  /* opacity: 0.5; */
  z-index: -1;
  object-fit: cover;
}

.viewFourTestMinePage .viewtop {
  display: flex;
  position: relative;
  margin-top: 50rem;
  flex-direction: row;
  align-items: center;
  /* justify-content: center; */
  justify-content: space-between;
  /* background-color: red; */
  width: 100%;
}

.viewFourTestMinePage .viewtop .textHistory {
  width: 170rem;
height: 58rem;
font-family: PingFang SC, PingFang SC;
font-weight: 500;
font-size: 30rem;
color: #626262;
line-height: 58rem;
text-align: center;
font-style: normal;
text-transform: none;
margin-right: 33rem;
background-color: white;
border-radius: 30rem;
cursor: pointer;
}

.viewFourTestMinePage .viewtop .imageIcon {
  margin-left: 50rem;
  height: 75rem;
  width: 75rem;
}

.viewFourTestMinePage .textQuestion {
  font-size: 68rem;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  margin-top: 130rem;
  line-height: 68rem;
  background: -webkit-linear-gradient(35.809007034530254deg, #C8BBFD 4%, #6F90FD 55%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* background: linear-gradient(190.83957416074898deg, #FFC6CD 2%, #F3A5FF 13%, #EF83FF 36%, #B716CF 71%); */
  /* background: linear-gradient(35.809007034530254deg, #C8BBFD 4%, #6F90FD 55%); */
}

.viewFourTestMinePage .viewimageMid {
  width: 100%;
  height: 464rem;
  display: flex;
  position: relative;
  margin-top: 90rem;
}

.viewFourTestMinePage .viewimageMid .imageMid {
  width: 100%;
  height: 464rem;
}

.viewFourTestMinePage .viewfooter {
  display: flex;
  position: absolute;
  bottom: 77rem;
  /* margin-top: 160rem; */
  height: 116rem;
  width: 465rem;
  align-self: center;
}

.viewFourTestMinePage .viewfooter .imagefooter {
  height: 116rem;
  width: 465rem;
}

.viewFourTestMinePage .viewStart {
  display: flex;
  position: relative;
  width: 538rem;
  height: 108rem;
  margin-top: 110rem;
}

.viewFourTestMinePage .viewStart .imagestart {
  width: 538rem;
  height: 108rem;
  object-fit: contain;
}

.viewFourTestMinePage .textResult {
  font-size: 36rem;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
  line-height: 56rem;
}

.viewFourTestMinePage .textStart {
  font-size: 36rem;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
  line-height: 56rem;
  position: absolute;
  bottom: 50rem;
  width: 100%;
}

.viewLogin {
  background-color: white;
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.viewLogin .viewTop {
  display: flex;
  height: 640rem;
  width: 100%;
  margin-top: -70rem;
}
.viewLogin .viewTop .imagebk {
  object-fit: contain;
  height: 640rem;
  width: 100%;
  margin-top: -70rem;
}

.viewLogin .viewBottom {
  flex: 1;
  display: flex;
  margin-top: -180rem;
  z-index: 2;
  /* background-color: red; */
  background: linear-gradient( 180deg, #FFFFFF 0%, #FFFFFF 100%);
  border-radius: 40rem;
  /* width: 750rem; */
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: 50rem;
  padding-top: 60rem;
  padding-right: 50rem;
}

.viewLogin .viewBottom .textTitle {
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 36rem;
  color: #17271B;
  line-height: 42rem;
}

.viewLogin .viewBottom .textTips {
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 27rem;
  color: #8F959E;
  line-height: 38rem;
  text-align: start;
  width: 654rem;
  margin-top: 20rem;
}

.viewLogin .viewBottom .viewSubmit {
  display: flex;
  position: relative;
  margin-top: 165rem;
  height: 88rem;
  width: 538rem;
  align-self: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.viewLogin .viewBottom .viewSubmit .imagesubmit {
  display: flex;
  position: relative;
  height: 88rem;
  width: 538rem;
}

.viewLogin .viewBottom .viewfooter {
  display: flex;
  position: absolute;
  bottom: 77rem;
  /* margin-top: 160rem; */
  height: 116rem;
  width: 465rem;
  align-self: center;
}

.viewLogin .viewBottom .viewfooter .imagefooter {
  height: 116rem;
  width: 465rem;
}