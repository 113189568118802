.View2_2 {
    width: 100%;
    height: 100%;
    display: flex;
    position: absolute;
    align-items: center;
    /* background-color: red; */
}

.View2_2 .backImageContain {
    width: 100%;
    height: 100%;
    /* background-color: yellow; */
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    transition: all .5s;
}

.View2_2 .content {
    /* background-color: yellow; */
    /* width: 70%; */
    /* z-index: 10; */
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* margin-top: -0rem; */
    flex: 1;
}

.View2_2 .backImage {
    top: 0;
    left: 0;
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    /* background-color: blue; */
}

.View2_2 .viewTitle {
    display: flex;
    position: relative;
    height: 1.22rem;
    width: 2.17rem;
    /* background-color: blue; */
    justify-content: center;
}

.View2_2 .imgTitle {
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: absolute;
}

.View2_2 .viewTitle .title {
    position: relative;
    height: .49rem;
    font-size: .35rem;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #000000;
    line-height: .41rem;
    -webkit-background-clip: text;
    z-index: 2;
    margin-top: .2rem;
}

.View2_2 .viewItem {
    display: flex;
    position: relative;
    height: 2.4rem;
    width: 13rem;
    margin-top: .0rem;
}

.View2_2 .imageItem {
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: absolute;
}

.View2_2 .textCHN {
    font-size: .2rem;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
    line-height: .33rem;
    letter-spacing: .01rem;
    -webkit-background-clip: text;
    position: absolute;
}

.View2_2 .textENG {
    font-size: .17rem;
    font-family: Inter-Italic, Inter;
    font-weight: normal;
    color: #616161;
    line-height: .30rem;
    -webkit-background-clip: text;
    position: absolute;
}

.View2_2 .text1 {
    height: .7rem;
    width: 4.6rem;
    left: 2.5rem;
    top: .3rem;
    text-align: start;
}

.View2_2 .text11 {
    width: 4.6rem;
    height: 1rem;
    text-align: start;
    left: 2.5rem;
    top: 1.05rem;
}

.View2_2 .text2 {
    height: .7rem;
    width: 4.6rem;
    right: 2.5rem;
    top: .3rem;
    text-align: start;
}

.View2_2 .text22 {
    width: 4.6rem;
    height: 1rem;
    text-align: start;
    right: 2.5rem;
    top: 1.05rem;
}

.View2_2 .text3 {
    height: .7rem;
    width: 4.6rem;
    left: 2.5rem;
    top: .3rem;
    text-align: start;
}

.View2_2 .text33 {
    width: 4.0rem;
    height: 1rem;
    text-align: start;
    left: 2.5rem;
    top: 1.1rem;
}

.View2_2 .animation {
    transition: all .5s;
    opacity: 0;
    /* opacity: 1; */
}

.View2_2 .active {
    transition-delay: 0.8s;
    opacity: 1;
}

.View2_2 .viewMore {
    display: flex;
    position: absolute;
    bottom: .3rem;
    right: -3.5rem;
}


.View2_2Item {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all .5s;
}

.View2_2TitleTop {
    font-size: .23rem;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: bold;
    color: #000000;
    line-height: .32rem;
}

.View2_2TitleBottom {
    font-size: .23rem;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: bold;
    line-height: .27rem;
}

.View2_2TitleBottom span {
    font-size: .23rem;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: bold;
    line-height: .27rem;
}

.View2_2Title {
    transition: all .5s;
    opacity: 0;
}

.View2_2Main {
    margin-top: .67rem;
    display: flex;
}

.View2_2Image {
    width: 3.27rem;
    height: 3.39rem;
    box-shadow: 0px .06rem .19rem 0px rgba(131, 147, 137, 0.2);
    border-radius: 1.2rem 0px 1.23rem 1.22rem;
    opacity: 1;
    border: 1px solid #FFFFFF;
    transition: all .5s;
    opacity: 0;
}

.View2_2MainRight {
    display: flex;
    flex-direction: column;
    width: 3.36rem;
    margin-left: .39rem;
    transition: all .5s;
    opacity: 0;
    text-align: left;
}

.View2_2MainRightText {
    font-size: .16rem;
    font-family: PingFang SC-Regular, PingFang SC;
    color: #000000;
    line-height: .31rem;
    letter-spacing: 1px;
}

.View2_2MainRightTextBold {
    font-size: .16rem;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: bold;
    color: #000000;
    line-height: .31rem;
    letter-spacing: 2px;
}

.View2_2MainRightTextLight {
    font-size: .16rem;
    font-family: Inter-Italic, Inter;
    color: #535353;
    line-height: .31rem;
    letter-spacing: 1px;
    margin-top: .34rem;
    text-align: left;
}

.View2_2 .rightView {
    position: absolute;
    display: flex;
    right: 0;
    bottom: 30%;
}