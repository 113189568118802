.View3box {
    /* width: 100%; */
    /* height: 100%; */
    display: flex;
    position: relative;
    align-items: start;
    justify-content: center;
    flex-direction: column;
    flex: 1;
    box-shadow: 0px 5px 9px 0px rgba(123, 123, 123, 0.38);
    border-radius: 21px 21px 21px 21px;
    background-color: white;
    width: 8.74rem;

}

.View3box .viewContentWhite {
    background-color: white;
    display: flex;
    position: relative;
    /* flex: 1; */
    align-items: start;
    justify-content: center;
    flex-direction: column;
    background: #FFFFFF;
    box-shadow: 0px -4px 20px 0px rgba(220, 168, 228, 0.72);
    border-radius: 0px 0px 21px 21px;
    height: 7.37rem;
    width: 100%;
    z-index: 2;
}

.View3box .textheaderContactus {
    display: flex;
    position: relative;
    height: .42rem;
    font-size: .26rem;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #000000;
    text-align: center;
    margin-right: .16rem;
    margin-left: .3rem;
}

.View3box .textEN {
    display: flex;
    position: relative;
    flex-direction: row;
}

.View3box .textENContact {
    font-size: .28rem;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #BD2AD3;
    line-height: .33rem;
}

.View3box .textENUs {
    font-size: .28rem;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #BD2AD3;
    line-height: .33rem;
    margin-left: .05rem;
}


.View3box .SectionName {
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    margin-left: .45rem;
    width: 5.44rem;
}

.View3box .alignStart {
    align-items: start;
}

.View3box .SectionName .SectionNameIn {
    width: 1.22rem;
    display: flex;
    flex-direction: row;
}


.View3box .textName {
    font-size: .16rem;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
    line-height: .19rem;
    text-align: start;
}

.View3box .textStar {
    font-size: .16rem;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #FF9900;
    line-height: .19rem;
}

.View3box .anotherStar {
    position: absolute;
    bottom: .22rem;
    right: .18rem;
}

.View3box .textNameEN {
    font-size: .14rem;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #8A8A8A;
    line-height: .16rem;
    -webkit-background-clip: text;
    text-align: start;
    margin-top: .06rem;
}


.View3box .MarginTop20 {
    margin-top: .21rem;
}

.View3box .MarginTop10 {
    margin-top: .07rem;
}

.View3box .sectionLeft {
    flex-direction: column;
    display: flex;
    position: relative;
    width: 1.24rem;
    align-items: start;
    justify-content: center;
    margin-right: .2rem;
}

.View3box .nameTextField {
    font-size: .16rem;
    height: .5rem;
}

.View3box .MuiOutlinedInput-input {
    font-size: .15rem !important;
    line-height: .2rem !important;
}

.View3box .viewTab {
    font-size: .1rem;
}

.ViewContact {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.ViewContact .ViewContainer {
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-top: .5rem;
    /* flex: 1; */
}

.ViewContact .ViewBottom {
    display: flex;
    position: absolute;
    height: 1.36rem;
    /* flex: 1; */
    /* background-color: #D05EE1; */
    width: 100%;
    bottom: 0;
}

.ViewContact .ViewContainer .ViewContainerLeft {
    /* width: 6.6rem; */
    /* background-color: yellowgreen; */
    margin-bottom: 1rem;
    /* border-radius: 10px; */
    /* border-width: 1px; */
}

.ViewContact .ViewContainer .ViewContainerRight {
    /* background-color: yellow; */
    margin-top: -1rem;
}

.View3box .viewContactUs {
    width: 1.62rem;
    height: .45rem;
    background-color: #D05EE1;
    box-shadow: 0px 11px 29px 0px rgba(189, 42, 211, 0.38);
    border-radius: .32rem .32rem .32rem .32rem;
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-top: .3rem; */
    position: relative;
    /* margin-bottom: .3rem; */
}

.View3box .viewDivContactUs {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: .3rem;
    margin-top: 1.4rem;
}

.View3box .textContactUs {
    font-size: .31rem;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    line-height: .36rem;
    -webkit-background-clip: text;
}


.View3box .MuiMenuItem-root {
    font-size: .15rem !important;
}

.View3box .ways {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: start;
    justify-content: center;
}

.View3box .ways .way {
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    margin-top: .03rem;
    margin-bottom: .03rem;
}

.View3box .ways .way .pot {
    display: flex;
    position: relative;
    height: .17rem;
    width: .17rem;
    background-color: #BD2AD3;
    border: 1px solid #FFC700;
    border-radius: .85rem;
}

.View3box .ways .way .potUnSelect {
    display: flex;
    position: relative;
    height: .17rem;
    width: .17rem;
    background-color: #F9F9F9;
    border: 1px solid #E4E4E4;
    border-radius: .85rem;
}

.View3box .ways .way .textCN {
    height: .25rem;
    font-size: .16rem;
    font-weight: 400;
    color: #000000;
    line-height: .21rem;
    margin-left: .1rem;
}

.View3box .ways .way .textEN {
    height: .22rem;
    font-size: .16rem;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: .19rem;
    margin-left: .1rem;
}

.ViewContainerRight .donatedivview{
    /* width: 2.8rem; */
    display: flex;
    position: relative;
    flex-direction: column;
}

.ViewContainerRight .donateview {
    width: 2.8rem;
    /* height: 5.78rem; */
    display: flex;
    position: relative;
    flex-direction: column;
    margin-left: .4rem;
    margin-right: .2rem;
    margin-top: .2rem;
    margin-bottom: .35rem;
    z-index: 1;
}

.ViewContainerRight .donateview .title {
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    flex: 0;
    height: .4rem;
    z-index: 1;
}

.ViewContainerRight .donateview .title .left {
    font-size: .26rem;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #000000;
    line-height: .30rem;
}

.ViewContainerRight .donateview .title .right {
    font-size: .27rem;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #FF9900;
    line-height: .32rem;
    margin-left: .15rem;
}

.ViewContainerRight .donateview .contentCN {
    margin-top: .1rem;
    font-size: .16rem;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
    line-height: .25rem;
    text-align: left;
}

.ViewContainerRight .donateview .contentEN {
    font-size: .16rem;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #666;
    line-height: .21rem;
    text-align: left;
}

.ViewContainerRight .donateview .marginTop10 {
    margin-top: .1rem;
}

.ViewContainerRight .donateview .mairginBottom40 {
    margin-bottom: .35rem;
}

.ViewContainerRight .donatedivview .backimg{
    z-index: 0;
    position: absolute;
    width: 2.8rem;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
}


.View3box .viewHeader {
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    background-color: #F7E5FA;
    width: 100%;
    height: .61rem;
    /* padding-left: .3rem; */
    box-shadow: 0px 5px 9px 0px rgba(123, 123, 123, 0.38);
    border-radius: 21px 21px 0px 0px;
}

.inquiryInputText {
    font-size: .08rem;
    /* height: .5rem; */
    width: 1rem;
}

.ViewContainerLeft .wxview {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    right: .4rem;
    z-index: 2;
}

.ViewContainerLeft .wxview .text1 {
    font-size: .18rem;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #000000;
    line-height: .24rem;
    letter-spacing: .01rem;
}

.ViewContainerLeft .wxview .viewqrcode {
    width: 2rem;
    height: 2rem;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
}

.ViewContainerLeft .wxview .viewqrcode .qrcode{
    width: 2rem;
    height: 2rem;
    display: flex;
    position: relative;
}

.ViewContainerLeft .wxview .text2 {
    font-size: .19rem;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: .29rem;
    letter-spacing: .02rem;
    margin-top: -.05rem;
}

/* .MuiSnackbarContent-message{
    font-size: 16px;
} */

.MuiAlert-message
{
    font-size: 16px;
}

/* .MuiAlert-action{
    font-size: 16px;
    height: 0;
    width: 0;
} */