.viewFourTestCISCQAPage {
  background-color: white;
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.viewNoticeFourTest {
  background-color: white;
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  object-fit: contain;
}

.viewNoticeFourTest .viewimagenotice {
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 1624rem;
  width: 100%;
  top: -70rem;
}

.viewNoticeFourTest .viewimagenotice .imagenotice {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.viewNoticeFourTest .viewImageStart {
  height: 119rem;
  width: 569rem;
  position: relative;
  margin-top: 1100rem;
}

.viewNoticeFourTest .viewBack {
  height: 60rem;
  width: 60rem;
  position: absolute;
  top: 21rem;
  left: 12rem;
}


.viewFourTestCISCQAPage .viewTop {
  width: 100%;
  height: 100rem;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  /* flex: 0; */
}

.viewFourTestCISCQAPage .viewTop .viewimageTop {
  width: 100%;
  height: 567rem;
  display: flex;
  position: relative;
}

.viewFourTestCISCQAPage .viewTop .viewTopIn {
  width: 100%;
  height: 60rem;
  /* background-color: red; */
  margin-top: -546rem;
  display: flex;
  position: relative;
  z-index: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.viewFourTestCISCQAPage .viewTop .viewTopIn .viewBack {
  height: 60rem;
  width: 60rem;
  margin-left: 12rem;
}

.viewFourTestCISCQAPage .viewTop .viewTopIn .viewBack .imageBack {
  height: 60rem;
  width: 60rem;
  margin-left: 12rem;
}

.viewFourTestCISCQAPage .viewTop .viewTopIn .textNO {
  font-family: PingFang SC, PingFang SC;
  font-weight: 600;
  font-size: 44rem;
  color: #000000;
  text-align: center;
  font-style: normal;
  text-transform: none;
  width: 90%;
  /* background-color: red; */
  margin-right: 10%;
}

.viewFourTestCISCQAPage .viewTop .viewTopIn .viewSliderOut {
  flex: 1;
  margin-right: 30rem;
  margin-left: 24rem;
  height: 20rem;
  background-color: #F2CDF8;
  border-color: #D754EA;
  border-width: 1rem;
  border-radius: 10rem;
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.viewFourTestCISCQAPage .viewTop .viewTopIn .viewSliderOut .viewSliderIn {
  background: linear-gradient(114deg, #FFC6CD 0%, #F3A5FF 26%, #EF83FF 42%, #B716CF 100%);
  height: 20rem;
  border-radius: 10rem;
  width: 50%;
  /* width: 100rem; */
}



.viewFourTestCISCQAPage .viewContent {
  display: flex;
  position: relative;
  /* margin-top: -400rem; */
  background-color: white;
  /* border-top-left-radius: 40rem;
  border-top-right-radius: 40rem; */
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-top: -1rem;
  background-image: url('https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/fourtypetest/qabottombk.png');
  background-size: contain;
  background-position: bottom;
  background-repeat: no-repeat;

}

.viewFourTestCISCQAPage .viewContent .viewScrollTrigger {
  width: 100%;
  height: 1rem;
  background-color: red;
}


.viewFourTestCISCQAPage .viewQuestion {
  background-color: white;
  border-top-left-radius: 40rem;
  border-top-right-radius: 40rem;
  /* display: flex; */
  position: relative;
  /* flex-direction: row;
  align-items: center;
  justify-content: flex-start; */
  /* flex-wrap: wrap; */
  padding-top: 35rem;
  width: 690rem;
  padding-bottom: 10rem;
  text-align: start;
  /* flex: 0; */
  padding-left: 30rem;
  padding-right: 30rem;
}

.viewFourTestCISCQAPage .viewQuestion .textMulti {
  width: 108rem;
  height: 41rem;
  background: linear-gradient(114deg, #FFC6CD 0%, #F3A5FF 26%, #EF83FF 42%, #B716CF 100%);
  border-top-right-radius: 20rem;
  border-bottom-left-radius: 20rem;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 26rem;
  color: #FFFFFF;
  line-height: 40rem;
  text-align: center;
  font-style: normal;
  text-transform: none;
  margin-right: 20rem;
  padding-left: 10rem;
  padding-right: 10rem;
  margin-top: 10rem;
  margin-bottom: 10rem;
  padding-top: 5rem;
  padding-bottom: 5rem;
}



.viewFourTestCISCQAPage .viewQuestion .textQuestion {
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 36rem;
  color: #666;
  line-height: 56rem;
  text-align: justified;
  font-style: normal;
  text-transform: none;
  white-space: pre-line;
  margin-top: 10rem;
}


.viewFourTestCISCQAPage .viewContent .viewContentNormalQuestion {
  width: 692rem;
  height: 385rem;
  border-radius: 40px 40px 40px 40px;
  background-image: url('https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/fourtypetest/qamidbk.png');
  background-size: cover;
  background-position: center;
  margin-top: 74rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.viewFourTestCISCQAPage .viewContent .viewContentNormalQuestion .textQ {
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 46rem;
  color: #000000;
  line-height: 69rem;
  text-align: left;
  font-style: normal;
  text-transform: none;
  margin-left: 40rem;
  margin-right: 40rem;
}

.viewFourTestCISCQAPage .viewContent .viewQAs {
  flex: 1;
  /* overflow-y: scroll; */
  /* overflow-x: scroll; */
  /* overflow: scroll; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  /* background-color: red; */
  margin-top: 70rem;
}

.viewFourTestCISCQAPage .viewContent .viewQAsType1 {
  flex: 1;
  /* overflow-y: scroll; */
  /* overflow-x: scroll; */
  /* overflow: scroll; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* background-color: red; */
  width: 100%;
}

.viewFourTestCISCQAPage .viewQAsType1 .viewAnswerType1Right {
  position: relative;
  background-image: url('https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/fourtypetest/qaright.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 311rem;
  height: 115rem;
  display: flex;
  margin-left: 47rem;

}

.viewFourTestCISCQAPage .viewQAsType1 .viewAnswerSelectType1Right {
  position: relative;

  background-image: url('https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/fourtypetest/qarightselect.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  width: 311rem;
  height: 115rem;
  margin-left: 47rem;

}

.viewFourTestCISCQAPage .viewQAsType1 .viewAnswerType1Wrong {
  position: relative;
  background-image: url('https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/fourtypetest/qawrong.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  width: 311rem;
  height: 115rem;
}

.viewFourTestCISCQAPage .viewQAsType1 .viewAnswerSelectType1Wrong {
  position: relative;

  background-image: url('https://focus-training.oss-cn-hangzhou.aliyuncs.com/cisc/fourtypetest/qawrongselect3.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  width: 311rem;
  height: 115rem;
}


.viewFourTestCISCQAPage .viewAnswer {
  margin-top: 17rem;
  margin-bottom: 17rem;
  background: linear-gradient(152deg, rgba(141, 222, 255, 0.1) 0%, rgba(56, 199, 255, 0.1) 6%, rgba(197, 173, 255, 0.1) 44%, rgba(239, 131, 255, 0.1) 67%, rgba(198, 0, 227, 0.1) 100%);
  min-height: 86rem;
  border-radius: 43rem;
  align-self: center;
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: 40rem;
  margin-right: 40rem;
  width: 689rem;
}

.viewFourTestCISCQAPage .viewAnswerSelect {
  margin-top: 17rem;
  margin-bottom: 17rem;
  /* background: #FBDEFF; */
  background: linear-gradient(116deg, rgba(186, 229, 255, 0.8) 0%, rgba(184, 191, 255, 0.6) 48%, rgba(155, 179, 255, 0.6) 100%);
  min-height: 86rem;
  border-radius: 43rem;
  align-self: center;
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: 40rem;
  margin-right: 40rem;
  width: 689rem;
}

.viewFourTestCISCQAPage .textAnswer {
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 34rem;
  color: #2D2D2D;
  line-height: 40rem;
  text-align: center;
  font-style: normal;
  text-transform: none;
  margin-left: 40rem;
  margin-right: 40rem;
}

.viewFourTestCISCQAPage .textAnswerSelect {
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 34rem;
  color: #2D2D2D;
  line-height: 40rem;
  text-align: center;
  font-style: normal;
  text-transform: none;
  margin-left: 40rem;
  margin-right: 40rem;
}

.viewFourTestCISCQAPage .nextButton {
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 36rem;
  color: #FFFFFF;
  line-height: 102rem;
  text-align: center;
  font-style: normal;
  text-transform: none;
  width: 392rem;
  height: 102rem;
  border-radius: 51rem;
  margin-top: 54rem;
  margin-bottom: 205rem;
  flex: 0;
  background: linear-gradient( 125deg, #C8BBFD 26%, #6F90FD 100%);
  box-shadow: 0px 4px 8px 0px rgba(84,170,193,0.71);
}

.viewFourTestCISCQAPage .nextButtonGray {
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 36rem;
  color: #FFFFFF;
  line-height: 102rem;
  text-align: center;
  font-style: normal;
  text-transform: none;
  background-color: gainsboro;
  width: 392rem;
  height: 102rem;
  border-radius: 51rem;
  margin-top: 54rem;
  margin-bottom: 205rem;
  flex: 0;
}