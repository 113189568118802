.View1box {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* background-color: black; */
}

.View1box .purplePot {
    position: absolute;
    width: 5rem;
    height: 5rem;
    background: #BD2AD3;
    border-radius: 0rem 0rem 0rem 0rem;
    opacity: 0.5;
    filter: blur(3rem);
    top: -1rem;
    left: -1rem;
}

.View1box .yellowPot {
    width: 5.18rem;
    height: 5.18rem;
    background: #FFCC00;
    opacity: 0.75;
    filter: blur(3rem);
    position: absolute;
    right: 0rem;
    bottom: 0rem;
    overflow: hidden;
}

.View1box .rightView {
    position: absolute;
    display:flex;
    right: 0;
    bottom: 30%;
}

.View1box .bigImageContainer {
    /* position: absolute;
    bottom: 1.78rem;
    right: 1.45rem; */
    width: 8.1rem;
    height: 6.6rem;
    position: relative;
}

.View1box .bigImage {
    width: 8.1rem;
    height: 6.6rem;
}

.View1box .content {
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: center;
    /* margin-top: 2.4rem; */
    height: 61%;
}

.View1box .content .left {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    /* background-color: yellow; */
    z-index: 100;
}

.View1box .content .right {
    width: 8.1rem;
    height: 6.6rem;
    /* background-color: red; */
    margin-left: -2.2rem;
}

.View1box .content .left .line1 {
    height: .77rem;
    font-size: .51rem;
    font-family: Poppins-SemiBold, Poppins;
    font-weight: 600;
    color: #181818;
    line-height: .6rem;
    -webkit-background-clip: text;
    margin-top: .1rem;
}

.View1box .left .line2 {
    color: #BD2AD3;
    height: .77rem;
    font-size: .51rem;
    font-family: Poppins-SemiBold, Poppins;
    font-weight: 600;
    line-height: .6rem;
    -webkit-background-clip: text;
    margin-top: -0.18rem;
}

.View1box .left .line3 {
    height: .77rem;
    font-size: .51rem;
    font-family: Poppins-SemiBold, Poppins;
    font-weight: 600;
    color: #181818;
    line-height: .6rem;
    -webkit-background-clip: text;
    margin-top: -0.18rem;
}

.View1box .left .line4 {
    /* width: 8rem; */
    height: .81rem;
    font-size: .58rem;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #000000;
    line-height: .68rem;
    letter-spacing: .04rem;
    margin-top: .1rem;
    text-align: left;
}

.View1box .line5 {
    width: 5.9rem;
    height: .8rem;
    font-size: .24rem;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #181818;
    line-height: .4rem;
    -webkit-background-clip: text;
    text-align: left;
    margin-top: .38rem;
    letter-spacing: .01rem;

}

.View1box .left .line6 {
    font-size: .22rem;
    font-family: Poppins-Regular, Poppins;
    font-weight: 400;
    color: #666666;
    -webkit-background-clip: text;
}

.View1box .firstline6 {
    margin-top: .26rem;
}

.View1box .viewContactUs {
    width: 2.18rem;
    height: .63rem;
    background-color: #D05EE1;
    box-shadow: 0px 11px 29px 0px rgba(189, 42, 211, 0.38);
    border-radius: .32rem .32rem .32rem .32rem;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: .4rem;
    cursor: pointer;
}

.View1box .textContactUs {
    font-size: .30rem;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #FFFFFF;
    line-height: .36rem;
    -webkit-background-clip: text;
}