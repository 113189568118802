.viewCISCQAPage {
  background-color: white;
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.viewNotice {
  background-color: white;
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  object-fit: contain;
}

.viewNotice .viewimagenotice {
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 1624rem;
  width: 100%;
  top: -70rem;
}

.viewNotice .viewimagenotice .imagenotice {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.viewNotice .viewImageStart {
  height: 88rem;
  width: 538rem;
  position: relative;
  margin-top: 1100rem;
  cursor: pointer;
}

.viewNotice .viewBack {
  height: 60rem;
  width: 60rem;
  position: absolute;
  top: 21rem;
  left: 12rem;
  cursor: pointer;
}


.viewCISCQAPage .viewTop {
  width: 100%;
  height: 100rem;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  /* flex: 0; */
}

.viewCISCQAPage .viewTop .viewimageTop {
  width: 100%;
  height: 567rem;
  display: flex;
  position: relative;
}

.viewCISCQAPage .viewTop .viewTopIn {
  width: 100%;
  height: 60rem;
  /* background-color: red; */
  margin-top: -546rem;
  display: flex;
  position: relative;
  z-index: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.viewCISCQAPage .viewTop .viewTopIn .viewBack {
  height: 60rem;
  width: 60rem;
  margin-left: 12rem;
}

.viewCISCQAPage .viewTop .viewTopIn .viewBack .imageBack{
  height: 60rem;
  width: 60rem;
  margin-left: 12rem;
}

.viewCISCQAPage .viewTop .viewTopIn .textNO {
  font-family: PingFang SC, PingFang SC;
font-weight: 500;
font-size: 30rem;
color: #000000;
text-align: left;
font-style: normal;
text-transform: none;
margin-left: 30rem;
}

.viewCISCQAPage .viewTop .viewTopIn .viewSliderOut {
  flex: 1;
  margin-right: 30rem;
  margin-left: 24rem;
  height: 20rem;
  background-color: #F2CDF8;
  border-color: #D754EA;
  border-width: 1rem;
  border-radius: 10rem;
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.viewCISCQAPage .viewTop .viewTopIn .viewSliderOut .viewSliderIn {
  background: linear-gradient( 114deg, #FFC6CD 0%, #F3A5FF 26%, #EF83FF 42%, #B716CF 100%);
  height: 20rem;
  border-radius: 10rem;
  width: 50%;
  /* width: 100rem; */
}



.viewCISCQAPage .viewContent {
  display: flex;
  position: relative;
  /* margin-top: -400rem; */
  background-color: white;
  /* border-top-left-radius: 40rem;
  border-top-right-radius: 40rem; */
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
/* margin-top: 5rem; */
}

.viewCISCQAPage .viewContent .viewScrollTrigger {
  width: 100%;
  height: 1rem;
  background-color: red;
}


.viewCISCQAPage .viewQuestion {
  background-color: white;
  border-top-left-radius: 40rem;
  border-top-right-radius: 40rem;
  /* display: flex; */
  position: relative;
  /* flex-direction: row;
  align-items: center;
  justify-content: flex-start; */
  /* flex-wrap: wrap; */
  padding-top: 25rem;
  width: 690rem;
  padding-bottom: 10rem;
  text-align: start;
  /* flex: 0; */
  padding-left: 30rem;
  padding-right: 30rem;
}

.viewCISCQAPage .viewQuestion .textMulti {
  width: 108rem;
  height: 41rem;
  background: linear-gradient( 114deg, #FFC6CD 0%, #F3A5FF 26%, #EF83FF 42%, #B716CF 100%);
  border-top-right-radius: 20rem;
  border-bottom-left-radius: 20rem;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 26rem;
  color: #FFFFFF;
  line-height: 40rem;
  text-align: center;
  font-style: normal;
  text-transform: none;
  margin-right: 20rem;
  padding-left: 10rem;
  padding-right: 10rem;
  margin-top: 10rem;
  margin-bottom: 10rem;
  padding-top: 5rem;
  padding-bottom: 5rem;
}



.viewCISCQAPage .viewQuestion .textQuestion {
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 36rem;
  color: #000000;
  line-height: 60rem;
  text-align: justified;
  font-style: normal;
  text-transform: none;
}

.viewCISCQAPage .viewContent .viewQAs {
  flex: 1;
  /* overflow-y: scroll; */
  /* overflow-x: scroll; */
  /* overflow: scroll; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  /* background-color: red; */
}

.viewCISCQAPage .viewAnswer {
  margin-top: 17rem;
  margin-bottom: 17rem;
  background: #F6F4F7;
  min-height: 86rem;
  border-radius: 43rem;
  align-self: center;  
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: 40rem;
  margin-right: 40rem;
  width: 689rem;
  cursor: pointer;
}

.viewCISCQAPage .viewAnswerSelect {
  margin-top: 17rem;
  margin-bottom: 17rem;
  background: #FBDEFF;
  min-height: 86rem;
  border-radius: 43rem;
  align-self: center;  
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: 40rem;
  margin-right: 40rem;
  width: 689rem;
  cursor: pointer;
}

.viewCISCQAPage .textAnswer {
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 34rem;
  color: #2D2D2D;
  line-height: 40rem;
  text-align: center;
  font-style: normal;
  text-transform: none;
  margin-left: 40rem;
  margin-right: 40rem;
}

.viewCISCQAPage .textAnswerSelect {
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 34rem;
  color: #2D2D2D;
  line-height: 40rem;
  text-align: center;
  font-style: normal;
  text-transform: none;
  margin-left: 40rem;
  margin-right: 40rem;
}

.viewCISCQAPage .nextButton {
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 36rem;
  color: #FFFFFF;
  line-height: 88rem;
  text-align: center;
  font-style: normal;
  text-transform: none;
  width: 305rem;
  height: 88rem;
  background: linear-gradient( 114deg, #FFC6CD 0%, #F3A5FF 26%, #EF83FF 42%, #B716CF 100%);
  border-radius: 44rem;
  margin-top: 32rem;
  margin-bottom: 60rem;
  flex: 0;
  cursor: pointer;
}

.viewCISCQAPage .nextButtonGray {
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 36rem;
  color: #FFFFFF;
  line-height: 88rem;
  text-align: center;
  font-style: normal;
  text-transform: none;
  width: 305rem;
  height: 88rem;
  background-color: gainsboro;
  border-radius: 44rem;
  margin-top: 32rem;
  margin-bottom: 60rem;
  flex: 0;
}