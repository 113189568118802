.ViewContactFormView {
  /* width: 100%; */
  /* height: 100%; */
  display: flex;
  position: relative;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  box-shadow: 0px 5px 9px 0px rgba(123, 123, 123, 0.38);
  border-radius: 21px 21px 21px 21px;
  background-color: white;
  width: 8.74rem;

}

.ViewContactFormView .viewContentWhiteDialog {
  background-color: white;
  display: flex;
  position: relative;
  /* flex: 1; */
  align-items: start;
  justify-content: center;
  flex-direction: column;
  background: #FFFFFF;
  box-shadow: 0px -4px 20px 0px rgba(220, 168, 228, 0.72);
  border-radius: 0px 0px 21px 21px;
  height: 7.97rem;
  width: 100%;
  z-index: 2;
}

.ViewContactFormView .viewContentWhite {
  background-color: white;
  display: flex;
  position: relative;
  /* flex: 1; */
  align-items: start;
  justify-content: center;
  flex-direction: column;
  background: #FFFFFF;
  box-shadow: 0px -4px 20px 0px rgba(220, 168, 228, 0.72);
  border-radius: 0px 0px 21px 21px;
  height: 7.47rem;
  width: 100%;
  z-index: 2;
}

.ViewContactFormView .textheaderContactus {
  display: flex;
  position: relative;
  height: .42rem;
  font-size: .26rem;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
  text-align: center;
  margin-right: .16rem;
  margin-left: .3rem;
  margin-top: .06rem;
}

.ViewContactFormView .textEN {
  display: flex;
  position: relative;
  flex-direction: row;
  margin-top: .02rem;
}

.ViewContactFormView .textENContact {
  font-size: .28rem;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #BD2AD3;
  line-height: .33rem;
}

.ViewContactFormView .textENUs {
  font-size: .28rem;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #BD2AD3;
  line-height: .33rem;
  margin-left: .05rem;
}


.ViewContactFormView .SectionName {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  margin-left: .45rem;
  width: 5.44rem;
}

.ViewContactFormView .alignStart {
  align-items: start;
}

.ViewContactFormView .SectionName .SectionNameIn {
  width: 1.22rem;
  display: flex;
  flex-direction: row;
}


.ViewContactFormView .textName {
  font-size: .16rem;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: .19rem;
  text-align: start;
}

.ViewContactFormView .textStar {
  font-size: .16rem;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #FF9900;
  line-height: .19rem;
}

.ViewContactFormView .anotherStar {
  position: absolute;
  bottom: .22rem;
  right: .18rem;
}

.ViewContactFormView .textNameEN {
  font-size: .14rem;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #8A8A8A;
  line-height: .16rem;
  -webkit-background-clip: text;
  text-align: start;
  margin-top: .06rem;
}


.ViewContactFormView .MarginTop20 {
  margin-top: .21rem;
}

.ViewContactFormView .MarginTop10 {
  margin-top: .07rem;
}

.ViewContactFormView .sectionLeft {
  flex-direction: column;
  display: flex;
  position: relative;
  width: 1.24rem;
  align-items: start;
  justify-content: center;
  margin-right: .2rem;
}

.ViewContactFormView .nameTextField {
  font-size: .16rem;
  height: .5rem;
}

.ViewContactFormView .MuiOutlinedInput-input {
  font-size: .15rem !important;
  line-height: .2rem !important;
}

.ViewContactFormView .viewTab {
  font-size: .1rem;
}

.ViewContactFormView .viewContactUs {
  width: 1.62rem;
  height: .45rem;
  background-color: #D05EE1;
  box-shadow: 0px 11px 29px 0px rgba(189, 42, 211, 0.38);
  border-radius: .32rem .32rem .32rem .32rem;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-top: .3rem; */
  position: relative;
  /* margin-bottom: .3rem; */
}

.ViewContactFormView .viewDivContactUs {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: .3rem;
  margin-top: 1.4rem;
  cursor: pointer;
}

.ViewContactFormView .textContactUs {
  font-size: .26rem;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  line-height: .36rem;
  -webkit-background-clip: text;
}


.ViewContactFormView .MuiMenuItem-root {
  font-size: .15rem !important;
}

.ViewContactFormView .ways {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: start;
  justify-content: center;
}

.ViewContactFormView .ways .way {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  margin-top: .03rem;
  margin-bottom: .03rem;
}

.ViewContactFormView .ways .way .pot {
  display: flex;
  position: relative;
  height: .15rem;
  width: .15rem;
  background-color: #BD2AD3;
  border: 1px solid #FFC700;
  border-radius: .85rem;
}

.ViewContactFormView .ways .way .potUnSelect {
  display: flex;
  position: relative;
  height: .15rem;
  width: .15rem;
  background-color: #F9F9F9;
  border: 1px solid #E4E4E4;
  border-radius: .85rem;
}

.ViewContactFormView .ways .way .textCN {
  height: .25rem;
  font-size: .16rem;
  font-weight: 400;
  color: #000000;
  line-height: .21rem;
  margin-left: .1rem;
}

.ViewContactFormView .ways .way .textEN {
  height: .22rem;
  font-size: .16rem;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: .19rem;
  margin-left: .1rem;
}

.ViewContactFormView .ways .way .textError {
  color: #dc3545;
}

.ViewContactFormView .viewHeader {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  background-color: #F7E5FA;
  width: 100%;
  height: .61rem;
  /* padding-left: .3rem; */
  box-shadow: 0px 5px 9px 0px rgba(123, 123, 123, 0.38);
  border-radius: 21px 21px 0px 0px;
}

.ViewContactFormView .inquiryInputText {
  font-size: .08rem;
  /* height: .5rem; */
  width: 1rem;
}

.ViewContactFormView .wxview {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  right: .4rem;
  z-index: 2;
}

.ViewContactFormView .wxview .text1 {
  font-size: .18rem;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
  line-height: .24rem;
  letter-spacing: .01rem;
  text-align: center;
}

.ViewContactFormView .wxview .viewqrcode {
  width: 2rem;
  height: 2rem;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}

.ViewContactFormView .wxview .viewqrcode .qrcode{
  width: 2rem;
  height: 2rem;
  display: flex;
  position: relative;
}

.ViewContactFormView .wxview .text2 {
  font-size: .19rem;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: .29rem;
  letter-spacing: .02rem;
  margin-top: -.05rem;
}

/* .MuiSnackbarContent-message{
  font-size: 16px;
} */

.ViewContactFormView .MuiAlert-message
{
  font-size: 32px;
}

.ViewContactFormView .MuiAlert-icon {
  height: 32px;
  width: 32px;
  align-items: center;
  justify-content: center;
  transform: scale(2);
  margin-top: 7px;
}

/* .MuiSvgIcon-root {
  height: 32px;
  width: 32px;
} */

/* .MuiAlert-action{
  font-size: 16px;
  height: 0;
  width: 0;
} */