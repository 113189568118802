.viewCISCReportPage {
  background-color: #F7F8FA;
  /* height: 100%; */
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.viewCISCReportPage .navitop {
  width: 100%;
  height: 80rem;
  display: flex;
  position: fixed;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  /* background-color: white; */
  background: linear-gradient( 103deg, #F5D7FF 0%, #FFE1E1 100%);
  z-index: 2;
  opacity: 0;
  transform: translateY(-100%);
  transition: opacity 0.3s, transform 0.3s;
}

.viewCISCReportPage .navitop.visible {
  opacity: 1;
  transform: translateY(0);
}

.viewCISCReportPage .navitop .textnavitop {
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 34rem;
  color: #2D2D2D;
  line-height: 60rem;
  letter-spacing: 1rem;
  text-align: justified;
  font-style: normal;
  text-transform: none;
  text-align: center;
  margin-left: 158rem;
  /* margin-bottom: 5rem; */
}

.viewCISCReportPage .navitop .viewtopBack {
  height: 60rem;
  width: 60rem;
  margin-left: 12rem;
  position: relative;
  /* margin-bottom: 5rem; */
  opacity: 0.8;
  cursor: pointer;
}

.viewCISCReportPage .bk {
  width: 100%;
  height: 658rem;
  display: flex;
  position: relative;
  margin-top: -70rem;
}

.viewCISCReportPage .left {
  position: absolute;
  display: flex;
  top: 18rem;
  left: 12rem;
  height: 60rem;
  width: 60rem;
  z-index: 2;
  cursor: pointer;
}

.viewCISCReportPage .left .imageBack {
  height: 60rem;
  width: 60rem;
}

.viewCISCReportPage .viewqy {
  width: 720rem;
  height: 1486rem;
  display: flex;
  position: relative;
  margin-top: -500rem;
}

.viewCISCReportPage .textQuestion {
  font-size: 48rem;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
  line-height: 56rem;
}

.viewCISCReportPage .textAnxiety {
  font-size: 24rem;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
  line-height: 56rem;
  margin-left: 20rem;
  margin-right: 20rem;
}

.viewCISCReportPage .textPressure {
  font-size: 24rem;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #9125c7;
  line-height: 56rem;
  margin-left: 20rem;
  margin-right: 20rem;
}

.viewCISCReportPage .textSatisfaction {
  font-size: 24rem;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #40c725;
  line-height: 56rem;
  margin-left: 20rem;
  margin-right: 20rem;
}

.viewCISCReportPage .viewAnxiety {
  margin-top: 40rem;
  width: 690rem;
  background: #FFFFFF;
  box-shadow: 0px 1rem 13rem 7rem rgba(126,126,126,0.08);
  border-radius: 100rem 20rem 100rem 20rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.viewCISCReportPage .viewAnxiety .viewAnxietyTitle {
  height: 145rem;
  width: 320rem;
  margin-top: 40rem;
  display: flex;
  position: relative;
}

.viewCISCReportPage .viewAnxiety .viewAnxietyMap {
  height: 353rem;
  width: 561rem;
  margin-top: 40rem;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
}

.viewCISCReportPage .viewAnxiety .textAnxietyScore {
  margin-top: 46rem;
  font-family: PingFang SC, PingFang SC;
  font-weight: 600;
  font-size: 36rem;
  color: #000000;
  line-height: 42rem;
  text-align: right;
  font-style: normal;
  text-transform: none;
}

.viewCISCReportPage .viewAnxiety .viewAnxietyNoticeTitle {
  height: 55rem;
  width: 354rem;
  display: flex;
  position: relative;
  margin-top: 50rem;
  align-self: flex-start;
  margin-left: 40rem;
}

.viewCISCReportPage .viewAnxiety .viewAnxietyNotice {
  margin-top: 26rem;
  width: 650rem;
  display: flex;
  position: relative;
  margin-top: 26rem;
  align-items: center;
  margin-bottom: 50rem;
  flex-direction: column;
  justify-content: flex-start;
}

.viewCISCReportPage .viewAnxiety .viewAnxietyNotice .imgAnxietyNotice {
  position: relative;
  height: 537rem;
  width: 650rem;
  object-fit: contain;
}

.viewCISCReportPage .viewAnxiety .viewAnxietyNotice .textAnxietyNotice {
  position: relative;
  margin-top: -510rem;
  width: 610rem;
  /* height: 780px; */
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 34rem;
  color: #2D2D2D;
  line-height: 60rem;
  letter-spacing: 1rem;
  text-align: justify;
  font-style: normal;
  text-transform: none;
}

.viewCISCReportPage .viewyiyu {
  margin-top: 40rem;
  width: 690rem;
  background: #FFFFFF;
  box-shadow: 0px 1rem 13rem 7rem rgba(126,126,126,0.08);
  border-radius: 100rem 20rem 100rem 20rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.viewCISCReportPage .viewyiyu .viewyiyuTitle {
  height: 145rem;
  width: 320rem;
  margin-top: 40rem;
  display: flex;
  position: relative;
}

.viewCISCReportPage .viewyiyu .viewyiyuMap {
  height: 353rem;
  width: 561rem;
  margin-top: 40rem;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
}

.viewCISCReportPage .viewyiyu .textyiyuScore {
  margin-top: 46rem;
  font-family: PingFang SC, PingFang SC;
  font-weight: 600;
  font-size: 36rem;
  color: #000000;
  line-height: 42rem;
  text-align: right;
  font-style: normal;
  text-transform: none;
}

.viewCISCReportPage .viewyiyu .viewyiyuNoticeTitle {
  height: 55rem;
  width: 354rem;
  display: flex;
  position: relative;
  margin-top: 50rem;
  align-self: flex-start;
  margin-left: 40rem;
}

.viewCISCReportPage .viewyiyu .viewyiyuNotice {
  margin-top: 26rem;
  width: 650rem;
  display: flex;
  position: relative;
  margin-top: 26rem;
  align-items: center;
  margin-bottom: 50rem;
  flex-direction: column;
  justify-content: flex-start;
}

.viewCISCReportPage .viewyiyu .viewyiyuNotice .imgyiyuNotice {
  position: relative;
  height: 537rem;
  width: 650rem;
  object-fit: contain;
  align-self: flex-start;
}

.viewCISCReportPage .viewyiyu .viewyiyuNotice .textyiyuNotice {
  position: relative;
  margin-top: -510rem;
  width: 610rem;
  /* height: 780px; */
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 34rem;
  color: #2D2D2D;
  line-height: 60rem;
  letter-spacing: 1rem;
  text-align: justify;
  font-style: normal;
  text-transform: none;
}


.viewCISCReportPage .viewyali {
  margin-top: 40rem;
  width: 690rem;
  background: #FFFFFF;
  box-shadow: 0px 1rem 13rem 7rem rgba(126,126,126,0.08);
  border-radius: 100rem 20rem 100rem 20rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.viewCISCReportPage .viewyali .viewyaliTitle {
  height: 89rem;
  width: 473rem;
  margin-top: 40rem;
  display: flex;
  position: relative;
}

.viewCISCReportPage .viewyali .viewyaliMap {
  height: 398rem;
  width: 539rem;
  margin-top: 40rem;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
}

.viewCISCReportPage .viewyali .textyaliScore {
  margin-top: 46rem;
  font-family: PingFang SC, PingFang SC;
  font-weight: 600;
  font-size: 36rem;
  color: #000000;
  line-height: 42rem;
  text-align: right;
  font-style: normal;
  text-transform: none;
}

.viewCISCReportPage .viewyali .viewyaliNoticeTitle {
  height: 55rem;
  width: 354rem;
  display: flex;
  position: relative;
  margin-top: 50rem;
  align-self: flex-start;
  margin-left: 40rem;
}

.viewCISCReportPage .viewyali .viewyaliNotice {
  margin-top: 26rem;
  width: 650rem;
  display: flex;
  position: relative;
  margin-top: 26rem;
  align-items: center;
  margin-bottom: 50rem;
  flex-direction: column;
  justify-content: flex-start;
}

.viewCISCReportPage .viewyali .viewyaliNotice .imgyaliNotice {
  position: relative;
  height: 537rem;
  width: 650rem;
  object-fit: contain;
}

.viewCISCReportPage .viewyali .viewyaliNotice .textyaliNotice {
  position: relative;
  margin-top: -510rem;
  width: 610rem;
  /* height: 780px; */
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 34rem;
  color: #2D2D2D;
  line-height: 60rem;
  letter-spacing: 1rem;
  text-align: justify;
  font-style: normal;
  text-transform: none;
}


.viewCISCReportPage .viewmanyidu {
  margin-top: 40rem;
  width: 690rem;
  background: #FFFFFF;
  box-shadow: 0px 1rem 13rem 7rem rgba(126,126,126,0.08);
  border-radius: 100rem 20rem 100rem 20rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.viewCISCReportPage .viewmanyidu .viewmanyiduTitle {
  height: 89rem;
  width: 593rem;
  margin-top: 40rem;
  display: flex;
  position: relative;
}

.viewCISCReportPage .viewmanyidu .viewmanyiduNotice {
  margin-top: 26rem;
  width: 650rem;
  display: flex;
  position: relative;
  margin-top: 26rem;
  align-items: center;
  margin-bottom: 50rem;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;
}

.viewCISCReportPage .viewmanyidu .viewmanyiduNotice .imgmanyiduNotice {
  position: relative;
  height: 537rem;
  width: 650rem;
  object-fit: contain;
}

.viewCISCReportPage .viewmanyidu .viewmanyiduNotice .textmanyiduNotice {
  position: relative;
  margin-top: -510rem;
  width: 610rem;
  /* height: 780px; */
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 34rem;
  color: #2D2D2D;
  line-height: 60rem;
  letter-spacing: 1rem;
  text-align: justify;
  font-style: normal;
  text-transform: none;
}

.viewCISCReportPage .viewzongti {
  margin-top: 40rem;
  width: 690rem;
  background: #FFFFFF;
  box-shadow: 0px 1rem 13rem 7rem rgba(126,126,126,0.08);
  border-radius: 100rem 20rem 100rem 20rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.viewCISCReportPage .viewzongti .viewzongtiTitle {
  height: 89rem;
  width: 476rem;
  margin-top: 40rem;
  display: flex;
  position: relative;
}

.viewCISCReportPage .viewzongti .viewzongtiNotice {
  margin-top: 26rem;
  width: 650rem;
  display: flex;
  position: relative;
  margin-top: 26rem;
  align-items: center;
  margin-bottom: 0rem;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;
}

.viewCISCReportPage .viewzongti .viewzongtiNotice .imgzongtiNotice {
  position: relative;
  height: 537rem;
  width: 650rem;
  object-fit: contain;
}

.viewCISCReportPage .viewzongti .viewzongtiNotice .textzongtiNotice {
  position: relative;
  margin-top: -510rem;
  width: 610rem;
  /* height: 780px; */
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 34rem;
  color: #2D2D2D;
  line-height: 60rem;
  letter-spacing: 1rem;
  text-align: justify;
  font-style: normal;
  text-transform: none;
}

.viewCISCReportPage .viewzongti .viewzongti2Title {
  height: 49rem;
  width: 286rem;
  margin-top: 50rem;
  display: flex;
  position: relative;
}

.viewCISCReportPage .viewzongti .viewzongti2Notice {
  margin-top: 26rem;
  width: 650rem;
  display: flex;
  position: relative;
  margin-top: 26rem;
  align-items: center;
  margin-bottom: 50rem;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;
}

.viewCISCReportPage .viewzongti .viewzongti2Notice .imgzongti2Notice {
  position: relative;
  height: 537rem;
  width: 650rem;
  object-fit: contain;
}

.viewCISCReportPage .viewzongti .viewzongti2Notice .textzongti2Notice {
  position: relative;
  margin-top: -510rem;
  width: 610rem;
  /* height: 780px; */
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 34rem;
  color: #2D2D2D;
  line-height: 60rem;
  letter-spacing: 1rem;
  text-align: justify;
  font-style: normal;
  text-transform: none;
  z-index: 1;
}

.viewCISCReportPage .viewzongti .viewzongti2Notice .light {
  position: relative;
  display: flex;
  align-self: flex-end;
  height: 161rem;
  width: 168rem;
  margin-top: -170rem;
  z-index: 0;
  margin-right: 20rem;
}

.viewCISCReportPage .viewDate {
  margin-top: 40rem;
  width: 720rem;
  height: 587rem;
  /* background: #FFFFFF; */
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}

.viewCISCReportPage .viewDate .imgDate {
  width: 720rem;
  height: 587rem;
  position: relative;
}

.viewCISCReportPage .viewDate .textDate {
  position: relative;
  background-color: white;
  width: 500rem;
  height: 60rem;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 34rem;
  color: #000000;
  line-height: 60rem;
  letter-spacing: 1rem;
  text-align: left;
  align-self: flex-start;
  font-style: normal;
  text-transform: none;
  margin-top: -190rem;
  margin-left: 70rem;
}

.viewCISCReportPage .viewFooter {
  margin-top: 94rem;
  height: 116rem;
  width: 465rem;
  margin-bottom: 114rem;
  display: flex;
  position: relative;
}

.viewCISCReportPage .imgzhizhen {
  height: 175rem;
  width: 47rem;
  position: relative;
  margin-top: 50rem;
  transform: rotate(50deg);
  transform-origin: 50% 90%;
}