.viewHeaderMobile {
  position: fixed;
  height: 80rem;
  width: 100%;
  background-color: rgba(255, 255, 255, .8);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
}

.viewHeaderMobile .left {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: 30rem;
}

.viewHeaderMobile .left .img {
  display: flex;
  position: relative;
  height: 75rem;
  width: 75rem;
  /* background-color: aqua; */
}

.viewHeaderMobile .left .text {
  margin-left: 16rem;
  font-size: 36rem;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #181818;
  line-height: 42rem;
}

.viewHeaderMobile .right {
  height: 40rem;
  width: 40rem;
  margin-right: 40rem;
}

.viewHeaderMobile .right .img {
  display: flex;
  position: relative;
  height: 40rem;
  width: 40rem;
  transition: transform 0.3s ease-in-out; /* 添加过渡效果 */
}

.viewHeaderMobile .rightrotate {
  transform: rotate(90deg);
  transform-origin: center center; /* 以中心点为基准点旋转 */
}