.StripeView {
    position: realative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* padding-top: .1rem; */
    padding-bottom: .1rem;
    padding-left: .2rem;
    padding-right: .2rem;
    text-align: center;
    flex: 1;
    /* background-color: green; */
}

.StripeView .StripeItemLast {
    width: 1.69rem;
    height: .54rem;
    background-color: #FFF1B9;
    box-shadow: 0px 5px 9px 0px rgba(123,123,123,0.38);
    border-radius: .32rem .32rem .32rem .32rem;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
}

.StripeView .StripeItemLasthover {
    width: 1.69rem;
    height: .54rem;
    background-color: #D05EE1;
    box-shadow: 0px 5px 9px 0px rgba(189,42,211,0.38);
    border-radius: .32rem .32rem .32rem .32rem;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
}

.StripeView .StripeItemhover {
    width: 1.69rem;
    height: .54rem;
    background-color: #D05EE1;
    box-shadow: 0px 5px 9px 0px rgba(189,42,211,0.38);
    border-radius: .32rem .32rem .32rem .32rem;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    color: #000000;
    margin-bottom: .24rem;
    cursor: pointer;

}

.StripeView .StripeItem {
    width: 1.69rem;
    height: .54rem;
    background-color: #FFF1B9;
    box-shadow: 0px 5px 9px 0px rgba(123,123,123,0.38);
    border-radius: .32rem .32rem .32rem .32rem;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: .24rem;
    position: relative;
    color: #000000;
}

.StripeView .StripeText {
    font-size: .22rem;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 500;
    color: #000000;
    line-height: .26rem;
    -webkit-background-clip: text;
    width: 1.69rem;
}

.StripeView .StripeTexthover {
    font-size: .22rem;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    line-height: .26rem;
    -webkit-background-clip: text;
    width: 1.69rem;
}

.StripeView .StripeTextLast {
    font-size: .22rem;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    line-height: .26rem;
    -webkit-background-clip: text;
}

.StripeView .ArrayItem{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    max-width: 5rem;
}