.rightContactUs {
    /* width: 100%; */
    /* height: 100%; */
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 2rem;
    height: .84rem;
    background: linear-gradient(114deg, #FFC6CD 0%, #F3A5FF 26%, #EF83FF 42%, #B716CF 100%);
    border-radius: .42rem 0 0 .42rem;
    border-width: 0;
    cursor: pointer;
}

.rightContactUs .image {
    height: .44rem;
    width: .44rem;
}

.rightContactUs .textcontactus1 {
    font-size: .2rem;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #FFFFFF;
    line-height: .25rem;
    -webkit-background-clip: text;
    letter-spacing: .1rem;
    text-align: left;
}

.rightContactUs .textcontactus2 {
    letter-spacing: .008rem;
}

.rightContactUs .left {
    height: .44rem;
    width: .44rem;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
}

.rightContactUs .right {
    margin-left: .1rem;
}