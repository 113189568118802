@font-face {
  font-family: 'Poppins-Bold';
  src: url('./Poppins-Bold.ttf') format('truetype');
  /* 其他字体格式（如果有的话） */
}

@font-face {
  font-family: 'Poppins-Light';
  src: url('./Poppins-Light.ttf') format('truetype');
  /* 其他字体格式（如果有的话） */
}

@font-face {
  font-family: 'Poppins-Medium';
  src: url('./Poppins-Medium.ttf') format('truetype');
  /* 其他字体格式（如果有的话） */
}

@font-face {
  font-family: 'Poppins-SemiBold';
  src: url('./Poppins-SemiBold.ttf') format('truetype');
  /* 其他字体格式（如果有的话） */
}

@font-face {
  font-family: 'Poppins-Regular';
  src: url('./Poppins-Regular.ttf') format('truetype');
  /* 其他字体格式（如果有的话） */
}

@font-face {
  font-family: 'Inter-Italic';
  src: url('./Inter-Italic.otf') format('truetype');
  /* 其他字体格式（如果有的话） */
}

@font-face {
  font-family: 'Inter-BoldItalic';
  src: url('./Inter-BoldItalic.otf') format('truetype');
  /* 其他字体格式（如果有的话） */
}

@font-face {
  font-family: 'PingFang SC-Medium';
  src: url('./PingFang-SC-Medium.otf') format('truetype');
  /* 其他字体格式（如果有的话） */
}

@font-face {
  font-family: 'PingFang SC-Regular';
  src: url('./PingFang-SC-Regular.ttf') format('truetype');
  /* 其他字体格式（如果有的话） */
}

@font-face {
  font-family: 'PingFang SC-Semibold';
  src: url('./PingFang-SC-Medium.otf') format('truetype');
}